import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Checkbox,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'

import InputField from './InputField'
import SortableTableHead from './SortableTableHead'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'

const columnsCategorie = [
  { label: 'Categoria', sortable: false },
  { label: 'Nome completo', sortable: false },
  { label: 'Mini Volley', sortable: false },
  { label: 'Propaganda', sortable: false },
  { label: 'Arbitraggio', sortable: false },
  { label: 'Opzioni', sortable: false },
]

const DialogCategorie = ({ codiceUtente, categorie, dummyUpdate }) => {
  const dispatch = useDispatch()

  // states to manage new categorie addition/update
  const [editingCategoria, setEditingCategoria] = useState(false)
  const [addingCategoria, setAddingCategoria] = useState(false)
  const [newCategoria, setNewCategoria] = useState()
  const [newNome, setNewNome] = useState()
  const [newIsMini, setNewIsMini] = useState()
  const [newIsPropaganda, setNewIsPropaganda] = useState()
  const [newNeedsReferee, setNewNeedsReferee] = useState()

  // add
  const addNewCategoria = async () => {
    if (!!newCategoria) {
      try {
        await sendRequest(
          `categorie/add`,
          'POST',
          JSON.stringify({
            categoria: newCategoria,
            nome: newNome,
            isMini: !!newIsMini,
            isPropaganda: !!newIsPropaganda,
            needsReferee: !!newNeedsReferee,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        dispatch({
          type: SET_ERROR,
          payload: 'Categoria aggiunta correttamente!',
        })
        clearNewCategoria()
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload: 'Impossibile aggiungere la categoria: nessun nome inserito!',
      })
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // clear categoria
  const clearNewCategoria = () => {
    setEditingCategoria(false)
    setAddingCategoria(false)
    setNewCategoria()
    setNewNome()
    setNewIsMini()
    setNewIsPropaganda()
    setNewNeedsReferee()
  }

  // edit categoria
  const editCategoria = async id => {
    if (!!id) {
      try {
        await sendRequest(
          `categorie/${id}`,
          'PUT',
          JSON.stringify({
            categoria: newCategoria,
            nome: newNome,
            isMini: !!newIsMini,
            isPropaganda: !!newIsPropaganda,
            needsReferee: !!newNeedsReferee,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        clearNewCategoria()
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload: 'Impossibile aggiornare la categoria: categoria non trovata!',
      })
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // delete categoria
  const deleteCategoria = async id => {
    if (!!id) {
      try {
        await sendRequest(`categorie/${id}`, 'DELETE', null, {
          Authorization: codiceUtente,
        })
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload: 'Impossibile eliminare la categoria: categoria non trovata!',
      })
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  return (
    <>
      &nbsp;
      {(!categorie && <h4>Categorie non trovate.</h4>) || (
        <>
          <Grid container item xs={12} spacing={3} alignItems='center'>
            {(!!addingCategoria && (
              <>
                <Grid item xs={4} md={2}>
                  <center>
                    <InputField
                      id='categoria'
                      value={newCategoria}
                      onChange={setNewCategoria}
                      autoFocus={true}
                      label={columnsCategorie[0].label}
                    />
                  </center>
                </Grid>
                <Grid item xs={8} md={3}>
                  <center>
                    <InputField
                      id='nome'
                      value={newNome}
                      onChange={setNewNome}
                      label={columnsCategorie[1].label}
                    />
                  </center>
                </Grid>
                <Grid item xs={4} md>
                  <center>
                    {columnsCategorie[2].label}
                    <Checkbox
                      id='isMini'
                      checked={!!newIsMini}
                      onChange={event => setNewIsMini(event.target.checked)}
                    />
                  </center>
                </Grid>
                <Grid item xs={4} md>
                  <center>
                    {columnsCategorie[3].label}
                    <Checkbox
                      id='isPropaganda'
                      checked={!!newIsPropaganda}
                      onChange={event =>
                        setNewIsPropaganda(event.target.checked)
                      }
                    />
                  </center>
                </Grid>
                <Grid item xs={4} md>
                  <center>
                    {columnsCategorie[4].label}
                    <Checkbox
                      id='needsReferee'
                      checked={!!newNeedsReferee}
                      onChange={event =>
                        setNewNeedsReferee(event.target.checked)
                      }
                    />
                  </center>
                </Grid>
                <Grid item xs={2} md={1}>
                  <center>
                    <IconButton
                      style={{ color: colours.blueDark }}
                      onClick={() => addNewCategoria()}
                    >
                      <Tooltip key='Salva' title='Salva'>
                        <SaveIcon />
                      </Tooltip>
                    </IconButton>
                  </center>
                </Grid>
                <Grid item xs={2} md={1}>
                  <center>
                    <IconButton
                      style={{ color: colours.red }}
                      onClick={() => clearNewCategoria()}
                    >
                      <Tooltip key='Annulla' title='Annulla'>
                        <ClearIcon />
                      </Tooltip>
                    </IconButton>
                  </center>
                </Grid>
              </>
            )) || (
              <Grid item xs={12}>
                <center>
                  <IconButton
                    style={{ color: colours.black }}
                    onClick={() => {
                      setEditingCategoria(false)
                      setAddingCategoria(true)
                      setNewCategoria()
                      setNewNome()
                      setNewIsMini()
                      setNewIsPropaganda()
                      setNewNeedsReferee()
                    }}
                  >
                    <AddIcon />
                    &nbsp; Nuova categoria
                  </IconButton>
                </center>
              </Grid>
            )}
          </Grid>
          &nbsp;
          {(categorie.length === 0 && <h4>Nessuna categoria trovata.</h4>) || (
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={categorie}
                  columns={columnsCategorie}
                />
                <TableBody>
                  {categorie.map((c, index) => {
                    return (
                      <TableRow
                        key={index}
                        style={{ backgroundColor: colours.white }}
                      >
                        {(!!editingCategoria &&
                          newCategoria === c.categoria && (
                            <>
                              <TableCell align='center' padding='checkbox'>
                                {c.categoria}
                              </TableCell>
                              <TableCell align='center' padding='checkbox'>
                                <InputField
                                  id='descrizione'
                                  value={newNome}
                                  onChange={setNewNome}
                                  autoFocus={true}
                                  label={columnsCategorie[1].label}
                                  mode='update'
                                />
                              </TableCell>
                              <TableCell align='center' padding='checkbox'>
                                <Checkbox
                                  id='isMini'
                                  checked={!!newIsMini}
                                  onChange={event =>
                                    setNewIsMini(event.target.checked)
                                  }
                                />
                              </TableCell>
                              <TableCell align='center' padding='checkbox'>
                                <Checkbox
                                  id='isPropaganda'
                                  checked={!!newIsPropaganda}
                                  onChange={event =>
                                    setNewIsPropaganda(event.target.checked)
                                  }
                                />
                              </TableCell>
                              <TableCell align='center' padding='checkbox'>
                                <Checkbox
                                  id='needsReferee'
                                  checked={!!newNeedsReferee}
                                  onChange={event =>
                                    setNewNeedsReferee(event.target.checked)
                                  }
                                />
                              </TableCell>
                              <TableCell align='center' padding='checkbox'>
                                <Grid container item>
                                  <Grid item xs>
                                    <IconButton
                                      style={{ color: colours.blueDark }}
                                      onClick={() => editCategoria(c.id)}
                                    >
                                      <Tooltip key='Salva' title='Salva'>
                                        <SaveIcon />
                                      </Tooltip>
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs>
                                    <IconButton
                                      style={{ color: colours.red }}
                                      onClick={() => clearNewCategoria()}
                                    >
                                      <Tooltip key='Annulla' title='Annulla'>
                                        <ClearIcon />
                                      </Tooltip>
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </>
                          )) || (
                          <>
                            <TableCell align='center'>{c.categoria}</TableCell>
                            <TableCell align='center'>{c.nome}</TableCell>
                            <TableCell align='center'>
                              {(c.isMini === true && 'SÌ') ||
                                (c.isMini === false && 'NO') ||
                                '?'}
                            </TableCell>
                            <TableCell align='center'>
                              {(c.isPropaganda === true && 'SÌ') ||
                                (c.isPropaganda === false && 'NO') ||
                                '?'}
                            </TableCell>
                            <TableCell align='center'>
                              {(c.needsReferee === true && 'SÌ') ||
                                (c.needsReferee === false && 'NO') ||
                                '?'}
                            </TableCell>
                            <TableCell align='center'>
                              <Grid container item>
                                <Grid item xs>
                                  <IconButton
                                    style={{ color: colours.blueDark }}
                                    onClick={() => {
                                      setEditingCategoria(true)
                                      setAddingCategoria(false)
                                      setNewCategoria(c.categoria)
                                      setNewNome(c.nome)
                                      setNewIsMini(c.isMini)
                                      setNewIsPropaganda(c.isPropaganda)
                                      setNewNeedsReferee(c.needsReferee)
                                    }}
                                  >
                                    <Tooltip key='Modifica' title='Modifica'>
                                      <EditIcon />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                                <Grid item xs>
                                  <IconButton
                                    style={{ color: colours.red }}
                                    onClick={() => deleteCategoria(c.id)}
                                  >
                                    <Tooltip key='Elimina' title='Elimina'>
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  categorie: state.home.categorie,
  dummyUpdate: state.home.dummyUpdate,
})

const ConnectedDialogCategorie = connect(mapStateToProps)(DialogCategorie)

export default ConnectedDialogCategorie
