import React from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'

import SelectionBar from '../components/SelectionBar'
import SortableTableHead from '../components/SortableTableHead'
import { sortFunctions } from '../hooks/sort-functions'
import { colours } from '../settings/settings'

let columnsSquadre = [
  { label: 'Progressivo', sortable: false },
  { label: 'Codice società', sortable: false },
  { label: 'Nome squadra', sortable: false },
]

const Squadre = ({
  categorie,
  iscrizioniLight,
  availableCategorie,
  selectedCategoria,
}) => {
  document.title = 'PGS Milano - Squadre'

  // sort functions
  const { sortCodiceSocieta, sortCategorie } = sortFunctions()

  const SquadreTable = ({ categoria }) => {
    const squadreTable = iscrizioniLight
      ?.filter(i => i.categoria === categoria)
      ?.sort((a, b) => sortCodiceSocieta(a.codiceSocieta, b.codiceSocieta))
      ?.map((i, index) => ({
        index: index + 1,
        codiceSocieta: +i.codiceSocieta,
        nomeSquadra: i.nomeSquadra,
      }))

    return (
      <>
        &nbsp;
        <h4 style={{ paddingLeft: 20 }}>
          Categoria{' '}
          {categorie?.find(c => c.categoria === categoria)?.nome || categoria}
        </h4>
        <TableContainer component={Paper}>
          <Table size='small'>
            <SortableTableHead table={squadreTable} columns={columnsSquadre} />
            <TableBody>
              {squadreTable.map((s, index) => {
                return (
                  <TableRow
                    key={index}
                    style={{ backgroundColor: colours.white }}
                  >
                    <TableCell align='center'>{s.index}</TableCell>
                    <TableCell align='center'>{s.codiceSocieta}</TableCell>
                    <TableCell align='center'>{s.nomeSquadra}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>Squadre iscritte</h2>
        </center>
      </Grid>
      <SelectionBar includeGirone={false} includeTeam={false} />
      {(!iscrizioniLight && (
        <Grid item xs={12}>
          <center>
            <h4>Squadre iscritte non trovate.</h4>
          </center>
        </Grid>
      )) ||
        (iscrizioniLight.length === 0 && (
          <Grid item xs={12}>
            <center>
              <h4>Nessuna squadra iscritta.</h4>
            </center>
          </Grid>
        )) || (
          <>
            {!!selectedCategoria && (
              <Grid container item xs={12} justifyContent='center'>
                {iscrizioniLight
                  .filter(i =>
                    !!selectedCategoria &&
                    selectedCategoria !== availableCategorie[0]
                      ? i.categoria === selectedCategoria
                      : true
                  )
                  .map(i => i.categoria)
                  .filter((v, i, s) => s.indexOf(v) === i)
                  .filter(categoria => !!categoria)
                  .sort((a, b) => sortCategorie(a, b))
                  .map(categoria => (
                    <Grid item xs={12} paddingX={3} key={categoria}>
                      <SquadreTable categoria={categoria} />
                    </Grid>
                  ))}
              </Grid>
            )}
          </>
        )}
    </Grid>
  )
}

const mapStateToProps = state => ({
  categorie: state.home.categorie,
  iscrizioniLight: state.home.iscrizioniLight,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
})

const ConnectedSquadre = connect(mapStateToProps)(Squadre)

export default ConnectedSquadre
