import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'

import Select from './Select'
import AutoComplete from './AutoComplete'
import { useSelection } from '../hooks/selection-hooks'

const SelectionBar = ({
  includeCampionato = true,
  includeCategoria = true,
  includeGirone = true,
  includeTeam = true,
  includeNumeroGara = false,
  includePeriodo = false,
  includeArbitro = false,
  includeSocieta = false,
  admin,
  arbitro,
  campionati,
  categorie,
  iscrizioniLight,
  dummyUpdate,
  selectedCampionatoGironiPubblicati,
  selectedCampionatoCalendariPubblicati,
  availableCampionati,
  selectedCampionato,
  availableCategorie,
  selectedCategoria,
  availableGironi,
  selectedGirone,
  availableTeams,
  selectedTeam,
  availableNumeriGara,
  selectedNumeroGara,
  availablePeriodi,
  selectedPeriodo,
  arbitri,
  selectedArbitro,
  affiliazioniLight,
  selectedSocieta,
}) => {
  const location = useLocation()

  const arePubblicati =
    location.pathname?.split('/')?.[1] === 'gironi'
      ? selectedCampionatoGironiPubblicati
      : location.pathname?.split('/')?.[1] === 'calendari'
      ? selectedCampionatoCalendariPubblicati
      : location.pathname?.split('/')?.[1] === 'classifiche'
      ? selectedCampionatoCalendariPubblicati
      : true

  const {
    setSelectedCampionato,
    setSelectedCategoria,
    setSelectedGirone,
    setSelectedTeam,
    setSelectedNumeroGara,
    setSelectedPeriodo,
    setSelectedArbitro,
    setSelectedSocieta,
  } = useSelection(
    admin,
    arbitro,
    campionati,
    availableCampionati,
    selectedCampionato,
    availableCategorie,
    selectedCategoria,
    availableGironi,
    selectedGirone,
    availableTeams,
    selectedTeam,
    availableNumeriGara,
    selectedNumeroGara,
    availablePeriodi,
    selectedPeriodo,
    arbitri,
    selectedArbitro,
    affiliazioniLight,
    selectedSocieta,
    iscrizioniLight,
    dummyUpdate,
    includeCampionato,
    includeCategoria,
    includeGirone,
    includeTeam,
    includeNumeroGara,
    includePeriodo,
    arePubblicati
  )

  return (
    <Grid container paddingX={2} columnSpacing={2}>
      {includeCampionato && (
        <Grid item xs={12} md={3}>
          <Select
            id='available-campionati'
            value={
              availableCampionati?.includes(selectedCampionato)
                ? selectedCampionato
                : undefined
            }
            options={availableCampionati}
            optionLabels={availableCampionati?.map(
              a => campionati?.find(c => c.codice === a)?.nome
            )}
            label='Campionato'
            disabled={!availableCampionati}
            onChange={setSelectedCampionato}
            errorText='Selezionare un campionato!'
          />
        </Grid>
      )}
      {includeCategoria && (
        <Grid item xs={12} md={3}>
          <Select
            id='available-categorie'
            value={
              availableCategorie?.includes(selectedCategoria)
                ? selectedCategoria
                : undefined
            }
            options={availableCategorie}
            optionLabels={[
              availableCategorie[0],
              ...availableCategorie
                ?.filter(a => a !== availableCategorie[0])
                ?.map(a => categorie?.find(c => c.categoria === a)?.nome),
            ]}
            label='Categoria'
            disabled={!availableCategorie || (!admin && !arePubblicati)}
            onChange={setSelectedCategoria}
            errorText='Selezionare una categoria!'
          />
        </Grid>
      )}
      {includeGirone && (
        <Grid item xs={12} md={2}>
          <Select
            id='available-gironi'
            value={
              availableGironi?.includes(selectedGirone)
                ? selectedGirone
                : undefined
            }
            options={availableGironi}
            optionLabels={availableGironi?.map(
              a =>
                (a === null && 'Senza girone') ||
                (a === 'finali' && 'Fasi finali') ||
                (a === 'avulsa' && 'Classifica avulsa') ||
                (a !== availableGironi[0] && `Girone ${a}`) ||
                a
            )}
            label='Girone'
            disabled={!availableGironi || (!admin && !arePubblicati)}
            onChange={setSelectedGirone}
            errorText='Selezionare un girone!'
          />
        </Grid>
      )}
      {includeTeam && (
        <Grid item xs={12} md={4}>
          <AutoComplete
            id='available-teams'
            value={selectedTeam}
            options={availableTeams?.filter(t =>
              iscrizioniLight?.map(i => i.id)?.includes(t?.id)
            )}
            groupBy={op =>
              (op?.girone === null && 'Senza girone') ||
              (op?.girone === 'finali' && 'Fasi finali') ||
              (op?.girone === 'avulsa' && 'Classifica avulsa') ||
              `Girone ${+op?.girone}`
            }
            getOptionLabel={op =>
              iscrizioniLight?.filter(i => i?.id === (op?.id || op))?.[0]
                ?.nomeSquadra || op?.id
            }
            isOptionEqualToValue={(op, value) => op?.id === value}
            label='Squadra'
            disabled={!availableTeams || (!admin && !arePubblicati)}
            onChange={i => {
              if (i?.girone) setSelectedGirone(i.girone)
              setSelectedTeam(i?.id)
            }}
          />
        </Grid>
      )}
      {includePeriodo && (
        <Grid item xs={12} md={3}>
          <Select
            id='available-periodi'
            value={
              availablePeriodi?.includes(selectedPeriodo)
                ? selectedPeriodo
                : undefined
            }
            options={availablePeriodi}
            label='Periodo'
            disabled={!availablePeriodi}
            onChange={setSelectedPeriodo}
            errorText='Selezionare un periodo!'
          />
        </Grid>
      )}
      {includeNumeroGara && (
        <Grid item xs={12} md={3}>
          <AutoComplete
            id='available-numeriGara'
            value={selectedNumeroGara}
            options={availableNumeriGara?.map(a => a.numeroGara)}
            label='Numero gara'
            onChange={op =>
              setSelectedNumeroGara(
                availableNumeriGara?.filter(a => a.numeroGara === op)?.[0]
              )
            }
          />
        </Grid>
      )}
      {includeArbitro && (
        <Grid item xs={12} md={6}>
          <AutoComplete
            id='available-arbitri'
            value={selectedArbitro}
            options={arbitri}
            getOptionLabel={op => {
              const a = arbitri?.find(a => a?.id === (op?.id || op))
              return `${a?.nome} ${a?.cognome}`
            }}
            isOptionEqualToValue={(op, value) => op?.id === value}
            label='Arbitro'
            disabled={!arbitri}
            onChange={i => setSelectedArbitro(i?.id)}
          />
        </Grid>
      )}
      {includeSocieta && (
        <Grid item xs={12} md={6}>
          <AutoComplete
            id='available-societa'
            value={selectedSocieta}
            options={affiliazioniLight}
            getOptionLabel={op => {
              const a = affiliazioniLight?.find(a => a?.id === (op?.id || op))
              return `${a?.codiceSocieta} - ${a?.nomeSocieta}`
            }}
            isOptionEqualToValue={(op, value) => op?.id === value}
            label='Società'
            disabled={!affiliazioniLight}
            onChange={i => setSelectedSocieta(i?.id)}
          />
        </Grid>
      )}
    </Grid>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  arbitro: state.home.arbitro,
  campionati: state.home.campionati,
  categorie: state.home.categorie,
  iscrizioniLight: state.home.iscrizioniLight,
  dummyUpdate: state.home.dummyUpdate,
  selectedCampionatoGironiPubblicati:
    state.home.selectedCampionatoGironiPubblicati,
  selectedCampionatoCalendariPubblicati:
    state.home.selectedCampionatoCalendariPubblicati,
  availableCampionati: state.home.availableCampionati,
  selectedCampionato: state.home.selectedCampionato,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
  availableGironi: state.home.availableGironi,
  selectedGirone: state.home.selectedGirone,
  availableTeams: state.home.availableTeams,
  selectedTeam: state.home.selectedTeam,
  availableNumeriGara: state.home.availableNumeriGara,
  selectedNumeroGara: state.home.selectedNumeroGara,
  availablePeriodi: state.home.availablePeriodi,
  selectedPeriodo: state.home.selectedPeriodo,
  arbitri: state.arbitri.arbitri,
  selectedArbitro: state.home.selectedArbitro,
  affiliazioniLight: state.home.affiliazioniLight,
  selectedSocieta: state.home.selectedSocieta,
})

const ConnectedSelectionBar = connect(mapStateToProps)(SelectionBar)

export default ConnectedSelectionBar
