import {
  RESET_HOME,
  SET_AUTH,
  SET_ADMIN,
  SET_SOCIETA,
  SET_ARBITRO,
  SET_DESIGNATORE,
  SET_OMOLOGATORE,
  SET_CODICE_UTENTE,
  SET_INFO_UTENTE,
  SET_SELECTED_FUNCTION,
  SET_AFFILIAZIONI_LIGHT,
  SET_ISCRIZIONI_LIGHT,
  SET_IMPOSTAZIONI,
  SET_CLASSIFICHE,
  SET_ARBITRI_LIGHT,
  SET_BACKEND_R,
  SET_ZONE,
  SET_DOMENICHE,
  SET_ACCOPPIAMENTI,
  SET_CAMPIONATI,
  SET_CATEGORIE,
  SET_TABELLONI,
  SET_OPEN_UPLOAD_DIALOG,
  SET_UPLOAD_DIALOG_PHRASE,
  SET_UPLOAD_DIALOG_SUCCESS,
  SET_ERROR,
  SET_DUMMY_UPDATE,
  SET_IS_MOBILE,
  SET_IS_OPEN_DRAWER,
  SET_IS_OPEN_INFO_SOCIETA,
  SET_LOADING_SPOSTAMENTI,
  SET_LOADING_ARBITRI,
  SET_LOADING_DESIGNAZIONI,
  SET_LOADING_ARBITRAGGI,
  SET_SELECTED_CAMPIONATO_ISCRIZIONI_APERTE,
  SET_SELECTED_CAMPIONATO_GIRONI_PROVVISORI,
  SET_SELECTED_CAMPIONATO_GIRONI_PUBBLICATI,
  SET_SELECTED_CAMPIONATO_CALENDARI_PROVVISORI,
  SET_SELECTED_CAMPIONATO_CALENDARI_PUBBLICATI,
  SET_SELECTED_CAMPIONATO_SPOSTAMENTI_GRATUITI,
  SET_AVAILABLE_CAMPIONATI,
  SET_SELECTED_CAMPIONATO,
  SET_AVAILABLE_CATEGORIE,
  SET_SELECTED_CATEGORIA,
  SET_AVAILABLE_GIRONI,
  SET_SELECTED_GIRONE,
  SET_AVAILABLE_TEAMS,
  SET_SELECTED_TEAM,
  SET_AVAILABLE_NUMERI_GARA,
  SET_SELECTED_NUMERO_GARA,
  SET_AVAILABLE_PERIODI,
  SET_SELECTED_PERIODO,
  SET_SELECTED_ARBITRO,
  SET_SELECTED_SOCIETA,
  SET_SELECTED_NUMERI_GARA,
  SET_SELECTED_DESIGNAZIONI,
  SET_DASHBOARD_STATS,
  SET_OLD_PASSWORD,
  SET_NEW_PASSWORD,
} from './types'

const INIT_STATE = {
  isMobile:
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ),
  isOpenDrawer:
    !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ),
  availableCategorie: ['Tutte'],
  availableGironi: ['Tutti'],
  availablePeriodi: ['Tutte'],
  oldPassword: '',
  newPassword: '',
}

export const homeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_HOME:
      return INIT_STATE
    case SET_AUTH:
      return { ...state, auth: action.payload }
    case SET_ADMIN:
      return { ...state, admin: action.payload }
    case SET_SOCIETA:
      return { ...state, societa: action.payload }
    case SET_ARBITRO:
      return { ...state, arbitro: action.payload }
    case SET_DESIGNATORE:
      return { ...state, designatore: action.payload }
    case SET_OMOLOGATORE:
      return { ...state, omologatore: action.payload }
    case SET_CODICE_UTENTE:
      return { ...state, codiceUtente: action.payload }
    case SET_INFO_UTENTE:
      return { ...state, infoUtente: action.payload }
    case SET_SELECTED_FUNCTION:
      return { ...state, selectedFunction: action.payload }
    case SET_AFFILIAZIONI_LIGHT:
      return { ...state, affiliazioniLight: action.payload }
    case SET_ISCRIZIONI_LIGHT:
      return { ...state, iscrizioniLight: action.payload }
    case SET_IMPOSTAZIONI:
      return { ...state, impostazioni: action.payload }
    case SET_CLASSIFICHE:
      return { ...state, classifiche: action.payload }
    case SET_ARBITRI_LIGHT:
      return { ...state, arbitriLight: action.payload }
    case SET_BACKEND_R:
      return { ...state, backendR: action.payload }
    case SET_ZONE:
      return { ...state, zone: action.payload }
    case SET_DOMENICHE:
      return { ...state, domeniche: action.payload }
    case SET_ACCOPPIAMENTI:
      return { ...state, accoppiamenti: action.payload }
    case SET_CAMPIONATI:
      return { ...state, campionati: action.payload }
    case SET_CATEGORIE:
      return { ...state, categorie: action.payload }
    case SET_TABELLONI:
      return { ...state, tabelloni: action.payload }
    case SET_OPEN_UPLOAD_DIALOG:
      return { ...state, openUploadDialog: action.payload }
    case SET_UPLOAD_DIALOG_PHRASE:
      return { ...state, uploadDialogPhrase: action.payload }
    case SET_UPLOAD_DIALOG_SUCCESS:
      return { ...state, uploadDialogSuccess: action.payload }
    case SET_ERROR:
      return { ...state, error: action.payload }
    case SET_DUMMY_UPDATE:
      return { ...state, dummyUpdate: action.payload }
    case SET_IS_MOBILE:
      return { ...state, isMobile: action.payload }
    case SET_IS_OPEN_DRAWER:
      return { ...state, isOpenDrawer: action.payload }
    case SET_IS_OPEN_INFO_SOCIETA:
      return { ...state, isOpenInfoSocieta: action.payload }
    case SET_LOADING_SPOSTAMENTI:
      return { ...state, loadingSpostamenti: action.payload }
    case SET_LOADING_ARBITRI:
      return { ...state, loadingArbitri: action.payload }
    case SET_LOADING_DESIGNAZIONI:
      return { ...state, loadingDesignazioni: action.payload }
    case SET_LOADING_ARBITRAGGI:
      return { ...state, loadingArbitraggi: action.payload }
    case SET_SELECTED_CAMPIONATO_ISCRIZIONI_APERTE:
      return { ...state, selectedCampionatoIscrizioniAperte: action.payload }
    case SET_SELECTED_CAMPIONATO_GIRONI_PROVVISORI:
      return { ...state, selectedCampionatoGironiProvvisori: action.payload }
    case SET_SELECTED_CAMPIONATO_GIRONI_PUBBLICATI:
      return { ...state, selectedCampionatoGironiPubblicati: action.payload }
    case SET_SELECTED_CAMPIONATO_CALENDARI_PROVVISORI:
      return { ...state, selectedCampionatoCalendariProvvisori: action.payload }
    case SET_SELECTED_CAMPIONATO_CALENDARI_PUBBLICATI:
      return { ...state, selectedCampionatoCalendariPubblicati: action.payload }
    case SET_SELECTED_CAMPIONATO_SPOSTAMENTI_GRATUITI:
      return { ...state, selectedCampionatoSpostamentiGratuiti: action.payload }
    case SET_AVAILABLE_CAMPIONATI:
      return { ...state, availableCampionati: action.payload }
    case SET_SELECTED_CAMPIONATO:
      return { ...state, selectedCampionato: action.payload }
    case SET_AVAILABLE_CATEGORIE:
      return {
        ...state,
        availableCategorie: [
          ...INIT_STATE.availableCategorie,
          ...action.payload,
        ],
      }
    case SET_SELECTED_CATEGORIA:
      return { ...state, selectedCategoria: action.payload }
    case SET_AVAILABLE_GIRONI:
      return {
        ...state,
        availableGironi: [...INIT_STATE.availableGironi, ...action.payload],
      }
    case SET_SELECTED_GIRONE:
      return { ...state, selectedGirone: action.payload }
    case SET_AVAILABLE_TEAMS:
      return { ...state, availableTeams: action.payload }
    case SET_SELECTED_TEAM:
      return { ...state, selectedTeam: action.payload }
    case SET_AVAILABLE_NUMERI_GARA:
      return { ...state, availableNumeriGara: action.payload }
    case SET_SELECTED_NUMERO_GARA:
      return { ...state, selectedNumeroGara: action.payload }
    case SET_AVAILABLE_PERIODI:
      return {
        ...state,
        availablePeriodi: [...INIT_STATE.availablePeriodi, ...action.payload],
      }
    case SET_SELECTED_PERIODO:
      return { ...state, selectedPeriodo: action.payload }
    case SET_SELECTED_ARBITRO:
      return { ...state, selectedArbitro: action.payload }
    case SET_SELECTED_SOCIETA:
      return { ...state, selectedSocieta: action.payload }
    case SET_SELECTED_NUMERI_GARA:
      return { ...state, selectedNumeriGara: action.payload }
    case SET_SELECTED_DESIGNAZIONI:
      return { ...state, selectedDesignazioni: action.payload }
    case SET_DASHBOARD_STATS:
      return {
        ...state,
        dashboardStats: { ...state.dashboardStats, ...action.payload },
      }
    case SET_OLD_PASSWORD:
      return { ...state, oldPassword: action.payload }
    case SET_NEW_PASSWORD:
      return { ...state, newPassword: action.payload }
    default:
      return state
  }
}
