import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import PlaceIcon from '@mui/icons-material/Place'
import DeleteIcon from '@mui/icons-material/Delete'
import SportsIcon from '@mui/icons-material/Sports'
import WarningIcon from '@mui/icons-material/Warning'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

import Select from '../components/Select'
import DialogMini from '../components/DialogMini'
import SelectionBar from '../components/SelectionBar'
import ButtonGroup from '../components/ButtonGroup'
import ButtonUpload from '../components/ButtonUpload'
import ButtonDownload from '../components/ButtonDownload'
import ButtonRunDownload from '../components/ButtonRunDownload'
import SortableTableHead from '../components/SortableTableHead'
import { ColouredButton, TransparentButton } from '../components/Buttons'
import {
  colours,
  titlesCalendari as titles,
  phrasesCalendari as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { sortFunctions } from '../hooks/sort-functions'
import { utilsFunctions } from '../hooks/utils-functions'
import {
  SET_OPEN_UPLOAD_DIALOG,
  SET_UPLOAD_DIALOG_PHRASE,
  SET_UPLOAD_DIALOG_SUCCESS,
  SET_ERROR,
  SET_DUMMY_UPDATE,
} from '../container/home/types'
import {
  SET_CALENDARI,
  SET_GARA_INDIRIZZO,
  SET_ISCRIZIONE_INDIRIZZO,
} from '../container/calendari/types'
import { SET_ISCRIZIONI } from '../container/iscrizioni/types'

const Calendari = ({
  admin,
  omologatore,
  codiceUtente,
  campionati,
  openUploadDialog,
  uploadDialogPhrase,
  uploadDialogSuccess,
  dummyUpdate,
  selectedCampionatoCalendariProvvisori,
  selectedCampionatoCalendariPubblicati,
  selectedCampionato,
  availableCategorie,
  selectedCategoria,
  availableGironi,
  selectedGirone,
  selectedTeam,
  selectedNumeroGara,
  iscrizioni,
  calendari,
  newData,
  newOra,
  swapTeams,
  needsReferee,
  ripescaggioID,
  parzialiCasa,
  parzialiTrasferta,
  penalitaCasa,
  penalitaTrasferta,
  omologa,
  setVintiCasaMini,
  setVintiTrasfertaMini,
  parzialeGoldenCasa,
  parzialeGoldenTrasferta,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Calendari'

  const handleCloseUpload = () => {
    dispatch({ type: SET_OPEN_UPLOAD_DIALOG, payload: false })
    dispatch({ type: SET_UPLOAD_DIALOG_PHRASE, payload: undefined })
    dispatch({ type: SET_UPLOAD_DIALOG_SUCCESS, payload: undefined })
  }

  let columnsCalendari = [
    { label: 'Gara', sortable: false },
    { label: 'Giornata', sortable: false },
    { label: 'Data', sortable: false },
    { label: 'Ora', sortable: false },
    { label: 'Indirizzo', sortable: false },
    { label: 'Squadra in casa', sortable: false },
    { label: 'Squadra in trasferta', sortable: false },
    { label: 'Risultato', sortable: false },
    { label: 'Parziali', sortable: false },
  ]
  if (admin) {
    columnsCalendari = [
      ...columnsCalendari,
      { label: 'Opzioni', sortable: false },
    ]
  }

  // state to manage admin ButtonGroup selection
  let FILTERS = ['Tutte', 'Senza data', 'Da giocare']
  const [selectedFilter, setSelectedFilter] = useState(FILTERS[0])

  // sort functions
  const { sortCategorie, sortGironi, sortGiornate, sortNumeriGara } =
    sortFunctions()
  const sortCategorieM = useCallback(sortCategorie, [])
  const sortGironiM = useCallback(sortGironi, [])
  const sortGiornateM = useCallback(sortGiornate, [])

  // utils functions
  const { filterByMissingInfo } = utilsFunctions()

  const [isLoading, setIsLoading] = useState()
  const [waitingSpostamenti, setWaitingSpostamenti] = useState()
  const [acceptedDesignazioni, setAcceptedDesignazioni] = useState()
  const [uploadCampionato, setUploadCampionato] = useState()

  // state to manage the dialogs
  const [openEditCalendario, setOpenEditCalendario] = useState(false)
  const [openEditRisultato, setOpenEditRisultato] = useState(false)
  const [openEditPenalita, setOpenEditPenalita] = useState(false)
  const [openEditOmologa, setOpenEditOmologa] = useState(false)
  const [openEditArbitraggio, setOpenEditArbitraggio] = useState(false)
  const [openEditRipescaggio, setOpenEditRipescaggio] = useState(false)
  const [openDeleteGara, setOpenDeleteGara] = useState(false)
  const [openCarica, setOpenCarica] = useState(false)
  const [openIndirizzo, setOpenIndirizzo] = useState(false)
  const [editCalendario, setEditCalendario] = useState()
  const [editRisultato, setEditRisultato] = useState()
  const [editPenalita, setEditPenalita] = useState()
  const [editOmologa, setEditOmologa] = useState()
  const [editArbitraggio, setEditArbitraggio] = useState()
  const [editRipescaggio, setEditRipescaggio] = useState()
  const [deletingId, setDeletingId] = useState()

  // state to manage feedback dialog
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false)
  const [feedbackDialogTitle, setFeedbackDialogTitle] = useState()
  const [feedbackDialogPhrase, setFeedbackDialogPhrase] = useState()
  const [feedbackDialogSuccess, setFeedbackDialogSuccess] = useState()

  const handleClickOpenEditCalendario = id => {
    setEditCalendario(calendari.find(i => i.id === id))
    setOpenEditCalendario(true)
  }

  const handleClickOpenEditRisultato = id => {
    setEditRisultato(calendari.find(i => i.id === id))
    setOpenEditRisultato(true)
  }

  const handleClickOpenEditPenalita = id => {
    setEditPenalita(calendari.find(i => i.id === id))
    setOpenEditPenalita(true)
  }

  const handleClickOpenEditOmologa = id => {
    setEditOmologa(calendari.find(i => i.id === id))
    setOpenEditOmologa(true)
  }

  const handleClickOpenEditArbitraggio = id => {
    setEditArbitraggio(calendari.find(i => i.id === id))
    setOpenEditArbitraggio(true)
  }

  const handleClickOpenRipescaggio = id => {
    setEditRipescaggio(calendari.find(i => i.id === id))
    setOpenEditRipescaggio(true)
  }

  const handleClickOpenDeleteGara = id => {
    setDeletingId(id)
    setOpenDeleteGara(true)
  }

  const handleClickOpenCarica = () => {
    setOpenCarica(true)
  }

  const handleClickOpenIndirizzo = (gara, iscrizione) => {
    dispatch({ type: SET_GARA_INDIRIZZO, payload: gara })
    dispatch({ type: SET_ISCRIZIONE_INDIRIZZO, payload: iscrizione })
    setOpenIndirizzo(true)
  }

  const handleClickOpenFeedbackDialog = (success, title, phrase) => {
    setFeedbackDialogSuccess(!!success)
    setFeedbackDialogTitle(title)
    setFeedbackDialogPhrase(phrase)
    setOpenFeedbackDialog(true)
  }

  const handleCloseEditCalendario = () => setOpenEditCalendario(false)
  const handleCloseEditRisultato = () => setOpenEditRisultato(false)
  const handleCloseEditPenalita = () => setOpenEditPenalita(false)
  const handleCloseEditOmologa = () => setOpenEditOmologa(false)
  const handleCloseEditArbitraggio = () => setOpenEditArbitraggio(false)
  const handleCloseEditRipescaggio = () => setOpenEditRipescaggio(false)
  const handleCloseCarica = () => setOpenCarica(false)

  const handleCloseDeleteGara = () => {
    setOpenDeleteGara(false)
    setDeletingId()
  }

  const handleCloseIndirizzo = () => {
    setOpenIndirizzo(false)
    dispatch({ type: SET_GARA_INDIRIZZO })
  }

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false)
    setFeedbackDialogTitle()
    setFeedbackDialogPhrase()
    setFeedbackDialogSuccess()
  }

  // fetch calendari
  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      try {
        const data = await sendRequest(
          `calendari/campionato/${selectedCampionato}${
            (selectedNumeroGara && `/numero/${selectedNumeroGara}`) ||
            (selectedTeam && `/team/${selectedTeam}`) ||
            (selectedCategoria &&
              selectedCategoria !== availableCategorie[0] &&
              `/categoria/${selectedCategoria}`) ||
            ''
          }`
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare i calendari.',
          })
        } else {
          dispatch({
            type: SET_CALENDARI,
            payload: data.sort(
              (a, b) =>
                sortCategorieM(a.categoria, b.categoria) ||
                sortGironiM(a.girone, b.girone) ||
                sortGiornateM(a.giornata, b.giornata)
            ),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setIsLoading(false)
    }
    if (!!selectedCampionato && !!selectedCategoria) {
      fetch()
    } else {
      dispatch({ type: SET_CALENDARI })
    }
  }, [
    availableCategorie,
    selectedCampionato,
    dispatch,
    dummyUpdate,
    selectedCategoria,
    selectedNumeroGara,
    selectedTeam,
    sortGironiM,
    sortCategorieM,
    sortGiornateM,
  ])

  // fetch iscrizioni
  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      try {
        const data = await sendRequest(
          `iscrizioni/campionato/${selectedCampionato}${
            selectedCategoria && selectedCategoria !== availableCategorie[0]
              ? `/categoria/${selectedCategoria}`
              : ''
          }`
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le iscrizioni.',
          })
        } else {
          dispatch({ type: SET_ISCRIZIONI, payload: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setIsLoading(false)
    }
    if (!!selectedCampionato && !!selectedCategoria) {
      fetch()
    } else {
      dispatch({ type: SET_ISCRIZIONI })
    }
  }, [
    availableCategorie,
    dispatch,
    dummyUpdate,
    selectedCampionato,
    selectedCategoria,
  ])

  // fetch waiting spostamenti
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(
          `spostamenti/campionato/${selectedCampionato}/waiting`
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare gli spostamenti in sospeso.',
          })
        } else {
          setWaitingSpostamenti(data)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (
      selectedCampionato &&
      (admin || selectedCampionatoCalendariPubblicati)
    ) {
      fetch()
    } else {
      setWaitingSpostamenti()
    }
  }, [
    admin,
    dispatch,
    dummyUpdate,
    selectedCampionato,
    selectedCampionatoCalendariPubblicati,
  ])

  // fetch accepted designazioni
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(
          `designazioni/campionato/${selectedCampionato}/accepted`
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le designazioni.',
          })
        } else {
          setAcceptedDesignazioni(data)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (selectedCampionato) {
      fetch()
    } else {
      setAcceptedDesignazioni()
    }
  }, [dispatch, dummyUpdate, selectedCampionato])

  // edit calendario
  const editCalendarioFunction = async () => {
    if (!!editCalendario.id) {
      try {
        if (!!newData && !!newOra) {
          await sendRequest(
            `calendari/${editCalendario.id}/sposta`,
            'PATCH',
            JSON.stringify({
              data: newData.split('-').reverse().join('/'),
              ora: newOra,
            }),
            { 'Content-Type': 'application/json', Authorization: codiceUtente }
          )
        } else if (!!swapTeams) {
          await sendRequest(
            `calendari/${editCalendario.id}/inverti`,
            'PATCH',
            null,
            { Authorization: codiceUtente }
          )
        } else {
          await sendRequest(
            `calendari/${editCalendario.id}/sposta`,
            'PATCH',
            null,
            { Authorization: codiceUtente }
          )
        }
        handleCloseEditCalendario()
        setEditCalendario()
        handleClickOpenFeedbackDialog(
          true,
          titles.titleEditCalendario,
          phrases.phraseCalendarioSuccess
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          'Impossibile effettuare lo spostamento: nessuna gara selezionata!',
      })
    }
  }

  // update risultato
  const updateRisultato = async () => {
    if (!!editRisultato.id) {
      try {
        await sendRequest(
          `calendari/risultato/${editRisultato.id}`,
          'PATCH',
          JSON.stringify({
            parzialiCasa,
            parzialiTrasferta,
            setVintiCasaMini,
            setVintiTrasfertaMini,
            parzialeGoldenCasa,
            parzialeGoldenTrasferta,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseEditRisultato()
        setEditRisultato()
        handleClickOpenFeedbackDialog(
          true,
          titles.titleEditRisultato,
          phrases.phraseRisultatoSuccess
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          'Impossibile aggiornare il risultato: nessuna gara selezionata!',
      })
    }
  }

  // update penalità
  const updatePenalita = async () => {
    if (!!editPenalita.id) {
      try {
        await sendRequest(
          `calendari/penalita/${editPenalita.id}`,
          'PATCH',
          JSON.stringify({ penalitaCasa, penalitaTrasferta }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseEditPenalita()
        setEditPenalita()
        handleClickOpenFeedbackDialog(
          true,
          titles.titleEditPenalita,
          phrases.phrasePenalitaSuccess
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          'Impossibile aggiornare le penalità: nessuna gara selezionata!',
      })
    }
  }

  // update omologa
  const updateOmologa = async () => {
    if (!!editOmologa.id) {
      try {
        await sendRequest(
          `calendari/omologa/${editOmologa.id}`,
          'PATCH',
          JSON.stringify({ validHomologation: omologa }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseEditOmologa()
        setEditOmologa()
        handleClickOpenFeedbackDialog(
          true,
          titles.titleEditOmologa,
          phrases.phraseOmologaSuccess
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          "Impossibile aggiornare l'omologazione: nessuna gara selezionata!",
      })
    }
  }

  // update arbitraggio
  const updateArbitraggio = async () => {
    if (!!editArbitraggio.id) {
      try {
        await sendRequest(
          `calendari/arbitraggio/${editArbitraggio.id}`,
          'PATCH',
          JSON.stringify({ needsReferee }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseEditArbitraggio()
        handleClickOpenFeedbackDialog(
          true,
          titles.titleEditArbitraggio,
          phrases.phraseArbitraggioSuccess
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
        setEditArbitraggio()
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          "Impossibile aggiornare l'arbitraggio: nessuna gara selezionata!",
      })
    }
  }

  // perform ripescaggio
  const performRipescaggio = async () => {
    if (!!editRipescaggio.id) {
      try {
        await sendRequest(
          `calendari/ripescaggio/${editRipescaggio.id}`,
          'PATCH',
          JSON.stringify({ squadraID: ripescaggioID }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseEditRipescaggio()
        handleClickOpenFeedbackDialog(
          true,
          titles.titleEditRipescaggio,
          phrases.phraseRipescaggioSuccess
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
        setEditRipescaggio()
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          'Impossibile aggiornare il ripescaggio: nessuna gara selezionata!',
      })
    }
  }

  // delete gara
  const deleteGara = async () => {
    if (!!deletingId) {
      try {
        await sendRequest(`calendari/${deletingId}`, 'DELETE', null, {
          Authorization: codiceUtente,
        })
        handleCloseDeleteGara()
        handleClickOpenFeedbackDialog(
          true,
          titles.titleDeleteGara,
          phrases.phraseDeleteGaraSuccess
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
        setDeletingId()
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload: 'Impossibile eliminare la gara: nessuna gara selezionata!',
      })
    }
  }

  const fCalendari = filterByMissingInfo(calendari, FILTERS, selectedFilter)

  const CalendariTable = ({ categoria, girone }) => {
    const calendarioTable = fCalendari
      .filter(i => i.categoria === categoria && i.girone === girone)
      .sort(
        (a, b) =>
          sortGiornate(a.giornata, b.giornata) ||
          sortNumeriGara(a.numeroGara, b.numeroGara)
      )

    return (
      <>
        &nbsp;
        <h4 style={{ paddingLeft: 20 }}>
          Categoria {categoria} -{' '}
          {girone === 'finali' ? 'Fasi finali' : `Girone ${girone}`}
        </h4>
        <TableContainer component={Paper}>
          <Table size='small'>
            <SortableTableHead
              table={calendarioTable}
              columns={columnsCalendari.map(c =>
                girone === 'finali' && c.label === 'Giornata'
                  ? { ...c, label: 'Fase' }
                  : c
              )}
            />
            <TableBody>
              {calendarioTable.map((a, index) => {
                const propsCell = {
                  align: 'center',
                  style: {
                    borderBottomColor:
                      !selectedTeam &&
                      index < calendarioTable.length - 1 &&
                      calendarioTable[index].giornata !==
                        calendarioTable[index + 1].giornata &&
                      colours.greyVeryDark,
                  },
                }

                const needsReferee = !!a.needsReferee
                const requiresReferee = !!a.requiresReferee
                const requiresRefereeAccepted = acceptedDesignazioni?.includes(
                  a.id
                )
                const isHomologated = !!a.validHomologation

                const aSets =
                  typeof a.setVintiCasa === 'number' &&
                  typeof a.setVintiTrasferta === 'number'
                    ? `${a.setVintiCasa} - ${a.setVintiTrasferta}`
                    : ''

                const aPoints =
                  a.parzialiCasa?.length > 0 &&
                  a.parzialiTrasferta?.length > 0 &&
                  a.parzialiCasa.length === a.parzialiTrasferta.length
                    ? a.parzialiCasa
                        ?.map(
                          (_, i) =>
                            `${a.parzialiCasa[i]}-${a.parzialiTrasferta[i]}`
                        )
                        .join(' ')
                    : ''

                const aGolden = typeof a.parzialeGoldenCasa === 'number' &&
                  typeof a.parzialeGoldenTrasferta === 'number' && (
                    <b style={{ backgroundColor: colours.goldenSet }}>
                      {a.parzialeGoldenCasa}-{a.parzialeGoldenTrasferta}
                    </b>
                  )

                let fiColor
                let fiTitle
                if (
                  needsReferee ||
                  (requiresReferee && requiresRefereeAccepted)
                ) {
                  fiColor = colours.black
                  fiTitle = 'Arbitro PGS'
                } else if (requiresReferee && !requiresRefereeAccepted) {
                  fiColor = colours.blueLight
                  fiTitle = 'Arbitro richiesto'
                } else if (admin) {
                  fiColor = colours.greyLight
                  fiTitle = 'Arbitro non PGS'
                } else {
                  fiColor = 'transparent'
                  fiTitle = ''
                }

                const Fischietto = (
                  <Tooltip title={fiTitle}>
                    <SportsIcon style={{ color: fiColor }} />
                  </Tooltip>
                )

                let propsDate = {}
                if (admin)
                  propsDate = {
                    ...propsDate,
                    sx: { ...propsDate.sx, cursor: 'pointer' },
                    onClick: () => handleClickOpenEditCalendario(a.id),
                  }

                let propsSets = { sx: { whiteSpace: 'nowrap' } }
                if (admin || omologatore)
                  propsSets = {
                    ...propsSets,
                    sx: { ...propsSets.sx, cursor: 'pointer' },
                    onClick: () => handleClickOpenEditRisultato(a.id),
                  }

                let propsPoints = {
                  align:
                    a.parzialiCasa?.length === 1 &&
                    a.parzialiTrasferta?.length === 1 &&
                    !a.parzialeGoldenCasa &&
                    !a.parzialeGoldenTrasferta
                      ? 'center'
                      : 'left',
                  sx: { whiteSpace: 'nowrap' },
                }
                if (admin || omologatore)
                  propsPoints = {
                    ...propsPoints,
                    sx: { ...propsPoints.sx, cursor: 'pointer' },
                    onClick: () => handleClickOpenEditRisultato(a.id),
                  }

                const aSquadraCasa = iscrizioni.find(
                  i => i.id === a.squadraCasaID
                )
                const aSquadraTrasferta = iscrizioni.find(
                  i => i.id === a.squadraTrasfertaID
                )

                const aSquadraCasaNome = aSquadraCasa?.nomeSquadra
                const aSquadraTrasfertaNome = aSquadraTrasferta?.nomeSquadra

                return (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: waitingSpostamenti?.includes(a.id)
                        ? colours.highlight
                        : colours.white,
                      ':hover': { backgroundColor: colours.greyVeryLight },
                    }}
                  >
                    <TableCell {...propsCell}>
                      <Grid
                        container
                        alignItems='center'
                        justifyContent='space-between'
                      >
                        <Grid item>
                          {admin ? (
                            <IconButton
                              onClick={() =>
                                handleClickOpenEditArbitraggio(a.id)
                              }
                            >
                              {Fischietto}
                            </IconButton>
                          ) : (
                            Fischietto
                          )}
                        </Grid>
                        <Grid item>{a.numeroGara}</Grid>
                        <Grid item>
                          {(admin || omologatore) && isHomologated && (
                            <IconButton
                              onClick={() => handleClickOpenEditOmologa(a.id)}
                            >
                              <Tooltip title='Referto consegnato'>
                                <CheckCircleIcon
                                  style={{ color: colours.green }}
                                />
                              </Tooltip>
                            </IconButton>
                          )}
                          {(admin || omologatore) && !isHomologated && (
                            <IconButton
                              onClick={() => handleClickOpenEditOmologa(a.id)}
                            >
                              <Tooltip title='Referto non consegnato'>
                                <CheckCircleOutlineIcon
                                  style={{ color: colours.greyLight }}
                                />
                              </Tooltip>
                            </IconButton>
                          )}
                          {!admin && !omologatore && isHomologated && (
                            <Tooltip title='Referto consegnato'>
                              <CheckCircleIcon
                                style={{ color: colours.green }}
                              />
                            </Tooltip>
                          )}
                          {!admin && !omologatore && !isHomologated && (
                            <CheckCircleOutlineIcon
                              style={{ color: 'transparent' }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell {...propsCell}>{a.giornata}</TableCell>
                    <TableCell {...propsCell} {...propsDate}>
                      {a.data}
                    </TableCell>
                    <TableCell {...propsCell} {...propsDate}>
                      {a.ora}
                    </TableCell>
                    <TableCell
                      {...propsCell}
                      sx={{ ...propsCell.sx, cursor: 'pointer' }}
                    >
                      <PlaceIcon
                        style={{ color: colours.red }}
                        onClick={() =>
                          handleClickOpenIndirizzo(a, aSquadraCasa)
                        }
                      />
                    </TableCell>
                    <TableCell {...propsCell}>
                      {a.squadraCasaID === selectedTeam ? (
                        <b>{aSquadraCasaNome}</b>
                      ) : (
                        aSquadraCasaNome ||
                        (girone === 'finali' &&
                          ((admin && a.squadraCasaID === 'Ripescaggio' && (
                            <>
                              <i>Ripescaggio</i>
                              <IconButton
                                onClick={() => handleClickOpenRipescaggio(a.id)}
                              >
                                <Tooltip title='Scegli una squadra'>
                                  <EditIcon
                                    style={{ color: colours.blueDark }}
                                  />
                                </Tooltip>
                              </IconButton>
                            </>
                          )) || <i>{a.squadraCasaID}</i>)) || (
                          <i>Squadra non trovata</i>
                        )
                      )}
                    </TableCell>
                    <TableCell {...propsCell}>
                      {a.squadraTrasfertaID === selectedTeam ? (
                        <b>{aSquadraTrasfertaNome}</b>
                      ) : (
                        aSquadraTrasfertaNome ||
                        (girone === 'finali' &&
                          ((admin && a.squadraTrasfertaID === 'Ripescaggio' && (
                            <>
                              <i>Ripescaggio</i>
                              <IconButton
                                onClick={() => handleClickOpenRipescaggio(a.id)}
                              >
                                <Tooltip title='Scegli una squadra'>
                                  <EditIcon
                                    style={{ color: colours.blueDark }}
                                  />
                                </Tooltip>
                              </IconButton>
                            </>
                          )) || <i>{a.squadraTrasfertaID}</i>)) || (
                          <i>Squadra non trovata</i>
                        )
                      )}
                    </TableCell>
                    <TableCell {...propsCell} {...propsSets}>
                      {aSets}
                    </TableCell>
                    <TableCell {...propsCell} {...propsPoints}>
                      {aPoints}&nbsp;{aGolden}
                    </TableCell>
                    {admin && (
                      <TableCell {...propsCell}>
                        <Grid container item>
                          <Grid item xs>
                            <IconButton
                              style={{ color: colours.blueDark }}
                              onClick={() => handleClickOpenEditPenalita(a.id)}
                            >
                              <Tooltip title='Penalità'>
                                <WarningIcon />
                              </Tooltip>
                            </IconButton>
                          </Grid>
                          <Grid item xs>
                            <IconButton
                              style={{ color: colours.red }}
                              onClick={() => handleClickOpenDeleteGara(a.id)}
                            >
                              <Tooltip title='Elimina'>
                                <DeleteIcon />
                              </Tooltip>
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>{`Calendari${
            selectedCampionatoCalendariProvvisori ? ' provvisori' : ''
          }`}</h2>
        </center>
      </Grid>
      &nbsp;
      <SelectionBar includeNumeroGara={admin || omologatore} />
      {(!admin &&
        selectedCampionato &&
        !selectedCampionatoCalendariPubblicati && (
          <Grid item xs={12}>
            <center>
              <h4>Calendari non ancora pubblicati.</h4>
            </center>
          </Grid>
        )) || (
        <>
          {admin && (
            <>
              <Grid container item xs={12} spacing={5}>
                <Grid item xs={12} sm={6} lg={3}>
                  <center>
                    <ColouredButton
                      textcolour={colours.white}
                      textbold='bold'
                      backgroundcolour={colours.blueLight}
                      hovercolour={colours.blue}
                      onClick={handleClickOpenCarica}
                    >
                      Carica Calendari
                    </ColouredButton>
                    <Dialog
                      open={openCarica}
                      onClose={(_, reason) => {
                        if (
                          !['backdropClick', 'escapeKeyDown'].includes(reason)
                        )
                          handleCloseCarica()
                      }}
                      fullWidth={true}
                      componentsProps={{
                        backdrop: { backgroundcolor: colours.backDrop },
                      }}
                      transitionDuration={{ enter: 250, exit: 0 }}
                    >
                      <DialogTitle>Carica calendari</DialogTitle>
                      <DialogContent>
                        <Grid container item xs={12} spacing={3}>
                          <Grid item xs={12}>
                            <Select
                              id='campionato'
                              label='Campionato'
                              value={uploadCampionato}
                              options={campionati?.map(c => c.codice)}
                              optionLabels={campionati?.map(c => c.nome)}
                              onChange={setUploadCampionato}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <center>
                              Se in piattaforma ci sono già partite di
                              categorie/gironi presenti anche nel file che si
                              sta caricando, le partite in piattaforma verranno
                              eliminate e sostituite da quelle del file.
                              <br />
                              <br />
                              Esempio: se nel file ci sono delle partite del
                              girone 4 di U12, verranno eliminate dalla
                              piattaforma tutte le partite del girone 4 di U12 e
                              sostituite da quelle presenti nel file.
                            </center>
                          </Grid>
                          <Grid item xs={12}>
                            <center>
                              <ButtonUpload
                                buttonText='Carica CSV'
                                algorithm='05_UpdateCalendari'
                                fileName='Input'
                                runScript={true}
                                uploadCampionato={uploadCampionato}
                              />
                            </center>
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <TransparentButton onClick={handleCloseCarica}>
                          Chiudi
                        </TransparentButton>
                      </DialogActions>
                    </Dialog>
                  </center>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <ButtonDownload algorithm='05_UpdateCalendari' type='TXT' />
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <center>
                    <ButtonGroup
                      list={FILTERS}
                      value={selectedFilter}
                      func={setSelectedFilter}
                    />
                  </center>
                </Grid>
              </Grid>
              &nbsp;
            </>
          )}
          {(isLoading && (
            <Grid item xs={12} padding={5}>
              <center>
                <CircularProgress disableShrink={true} />
              </center>
            </Grid>
          )) ||
            (selectedCategoria &&
              selectedGirone &&
              ((!fCalendari?.length && (
                <Grid item xs={12}>
                  <center>
                    <h4>
                      Nessuna gara{' '}
                      {selectedFilter === FILTERS[1]
                        ? 'senza data'
                        : selectedFilter === FILTERS[2]
                        ? 'ancora da giocare'
                        : 'da visualizzare'}
                      .
                    </h4>
                  </center>
                </Grid>
              )) ||
                (iscrizioni?.length && (
                  <Grid container alignItems='center'>
                    <Grid item xs={12} md={7} paddingX={5}>
                      Le gare evidenziate hanno uno spostamento in sospeso.
                    </Grid>
                    <Grid item xs={12} md={5} padding={3}>
                      <ButtonRunDownload
                        buttonText='Scarica Excel'
                        algorithm='08_GenerateExcelCalendari'
                        type='XLSX'
                      />
                    </Grid>
                    <Grid container>
                      {fCalendari
                        .filter(i =>
                          !!selectedCategoria &&
                          selectedCategoria !== availableCategorie[0]
                            ? i.categoria === selectedCategoria
                            : true
                        )
                        .map(i => i.categoria)
                        .filter((v, i, s) => s.indexOf(v) === i)
                        .filter(categoria => !!categoria)
                        .sort()
                        .map(categoria =>
                          fCalendari
                            .filter(
                              i =>
                                i.categoria === categoria &&
                                (!!selectedGirone &&
                                selectedGirone !== availableGironi[0]
                                  ? i.girone === selectedGirone
                                  : true)
                            )
                            .map(i => i.girone)
                            .filter((v, i, s) => s.indexOf(v) === i)
                            .sort((a, b) => sortGironi(a, b))
                            .map(girone => (
                              <Grid
                                item
                                xs={12}
                                paddingX={3}
                                key={`${categoria}-${girone}`}
                              >
                                <CalendariTable
                                  categoria={categoria}
                                  girone={girone}
                                />
                              </Grid>
                            ))
                        )}
                    </Grid>
                  </Grid>
                ))))}
        </>
      )}
      <DialogMini
        open={openEditCalendario}
        handleClose={handleCloseEditCalendario}
        title={titles.titleEditCalendario}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={editCalendarioFunction}
        dialogCalendario={true}
        gara={editCalendario}
      />
      <DialogMini
        open={openEditRisultato}
        handleClose={handleCloseEditRisultato}
        title={titles.titleEditRisultato}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={updateRisultato}
        dialogRisultato={true}
        gara={editRisultato}
      />
      <DialogMini
        open={openEditPenalita}
        handleClose={handleCloseEditPenalita}
        title={titles.titleEditPenalita}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={updatePenalita}
        dialogPenalita={true}
        gara={editPenalita}
      />
      <DialogMini
        open={openEditOmologa}
        handleClose={handleCloseEditOmologa}
        title={titles.titleEditOmologa}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={updateOmologa}
        dialogOmologa={true}
        gara={editOmologa}
      />
      <DialogMini
        open={openEditArbitraggio}
        handleClose={handleCloseEditArbitraggio}
        title={titles.titleEditArbitraggio}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={updateArbitraggio}
        dialogArbitraggio={true}
        gara={editArbitraggio}
      />
      <DialogMini
        open={openEditRipescaggio}
        handleClose={handleCloseEditRipescaggio}
        title={titles.titleEditRipescaggio}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={performRipescaggio}
        dialogRipescaggio={true}
        gara={editRipescaggio}
      />
      <DialogMini
        open={openFeedbackDialog}
        handleClose={handleCloseFeedbackDialog}
        title={feedbackDialogTitle}
        textContent={feedbackDialogPhrase}
        textConfirm='Ok'
        triggerFunction={handleCloseFeedbackDialog}
        colourBackground={!!feedbackDialogSuccess ? undefined : colours.red}
        colourHover={!!feedbackDialogSuccess ? undefined : colours.redDark}
      />
      <DialogMini
        open={openUploadDialog}
        handleClose={handleCloseUpload}
        title={
          !!uploadDialogSuccess
            ? 'Caricamento effettuato'
            : 'Errore nel caricamento'
        }
        textContent={uploadDialogPhrase}
        textConfirm='Ok'
        triggerFunction={handleCloseUpload}
        colourBackground={!!uploadDialogSuccess ? undefined : colours.red}
        colourHover={!!uploadDialogSuccess ? undefined : colours.redDark}
      />
      <DialogMini
        open={openDeleteGara}
        handleClose={handleCloseDeleteGara}
        title={titles.titleDeleteGara}
        textContent={phrases.phraseDeleteGara}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={deleteGara}
        colourBackground={colours.red}
        colourHover={colours.redDark}
      />
      <DialogMini
        open={openIndirizzo}
        handleClose={handleCloseIndirizzo}
        title='Campo di gioco'
        textUndo='Chiudi'
        dialogIndirizzo={true}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  omologatore: state.home.omologatore,
  codiceUtente: state.home.codiceUtente,
  campionati: state.home.campionati,
  openUploadDialog: state.home.openUploadDialog,
  uploadDialogPhrase: state.home.uploadDialogPhrase,
  uploadDialogSuccess: state.home.uploadDialogSuccess,
  dummyUpdate: state.home.dummyUpdate,
  selectedCampionatoCalendariProvvisori:
    state.home.selectedCampionatoCalendariProvvisori,
  selectedCampionatoCalendariPubblicati:
    state.home.selectedCampionatoCalendariPubblicati,
  selectedCampionato: state.home.selectedCampionato,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
  availableGironi: state.home.availableGironi,
  selectedGirone: state.home.selectedGirone,
  selectedTeam: state.home.selectedTeam,
  selectedNumeroGara: state.home.selectedNumeroGara,
  iscrizioni: state.iscrizioni.iscrizioni,
  calendari: state.calendari.calendari,
  newData: state.calendari.newData,
  newOra: state.calendari.newOra,
  swapTeams: state.calendari.swapTeams,
  needsReferee: state.calendari.needsReferee,
  ripescaggioID: state.calendari.ripescaggioID,
  parzialiCasa: state.risultato.parzialiCasa,
  parzialiTrasferta: state.risultato.parzialiTrasferta,
  penalitaCasa: state.risultato.penalitaCasa,
  penalitaTrasferta: state.risultato.penalitaTrasferta,
  omologa: state.risultato.omologa,
  setVintiCasaMini: state.risultato.setVintiCasaMini,
  setVintiTrasfertaMini: state.risultato.setVintiTrasfertaMini,
  parzialeGoldenCasa: state.risultato.parzialeGoldenCasa,
  parzialeGoldenTrasferta: state.risultato.parzialeGoldenTrasferta,
})

const ConnectedCalendari = connect(mapStateToProps)(Calendari)

export default ConnectedCalendari
