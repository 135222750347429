export const RESET_HOME = '@@home/RESET_HOME'

export const SET_AUTH = '@@home/SET_AUTH'
export const SET_ADMIN = '@@home/SET_ADMIN'
export const SET_SOCIETA = '@@home/SET_SOCIETA'
export const SET_ARBITRO = '@@home/SET_ARBITRO'
export const SET_DESIGNATORE = '@@home/SET_DESIGNATORE'
export const SET_OMOLOGATORE = '@@home/SET_OMOLOGATORE'
export const SET_CODICE_UTENTE = '@@home/SET_CODICE_UTENTE'
export const SET_INFO_UTENTE = '@@home/SET_INFO_UTENTE'

export const SET_SELECTED_FUNCTION = '@@home/SET_SELECTED_FUNCTION'
export const SET_AFFILIAZIONI_LIGHT = '@@home/SET_AFFILIAZIONI_LIGHT'
export const SET_ISCRIZIONI_LIGHT = '@@home/SET_ISCRIZIONI_LIGHT'
export const SET_IMPOSTAZIONI = '@@home/SET_IMPOSTAZIONI'
export const SET_CLASSIFICHE = '@@home/SET_CLASSIFICHE'
export const SET_ARBITRI_LIGHT = '@@home/SET_ARBITRI_LIGHT'

export const SET_BACKEND_R = '@@home/SET_BACKEND_R'
export const SET_ZONE = '@@home/SET_ZONE'
export const SET_DOMENICHE = '@@home/SET_DOMENICHE'
export const SET_ACCOPPIAMENTI = '@@home/SET_ACCOPPIAMENTI'
export const SET_CAMPIONATI = '@@home/SET_CAMPIONATI'
export const SET_CATEGORIE = '@@home/SET_CATEGORIE'
export const SET_TABELLONI = '@@home/SET_TABELLONI'

export const SET_OPEN_UPLOAD_DIALOG = '@@home/SET_OPEN_UPLOAD_DIALOG'
export const SET_UPLOAD_DIALOG_PHRASE = '@@home/SET_UPLOAD_DIALOG_PHRASE'
export const SET_UPLOAD_DIALOG_SUCCESS = '@@home/SET_UPLOAD_DIALOG_SUCCESS'

export const SET_ERROR = '@@home/SET_ERROR'
export const SET_DUMMY_UPDATE = '@@home/SET_DUMMY_UPDATE'
export const SET_IS_MOBILE = '@@home/SET_IS_MOBILE'
export const SET_IS_OPEN_DRAWER = '@@home/SET_IS_OPEN_DRAWER'
export const SET_IS_OPEN_INFO_SOCIETA = '@@home/SET_IS_OPEN_INFO_SOCIETA'
export const SET_LOADING_SPOSTAMENTI = '@@home/SET_LOADING_SPOSTAMENTI'
export const SET_LOADING_ARBITRI = '@@home/SET_LOADING_ARBITRI'
export const SET_LOADING_DESIGNAZIONI = '@@home/SET_LOADING_DESIGNAZIONI'
export const SET_LOADING_ARBITRAGGI = '@@home/SET_LOADING_ARBITRAGGI'

export const SET_SELECTED_CAMPIONATO_ISCRIZIONI_APERTE =
  '@@home/SET_SELECTED_CAMPIONATO_ISCRIZIONI_APERTE'
export const SET_SELECTED_CAMPIONATO_GIRONI_PROVVISORI =
  '@@home/SET_SELECTED_CAMPIONATO_GIRONI_PROVVISORI'
export const SET_SELECTED_CAMPIONATO_GIRONI_PUBBLICATI =
  '@@home/SET_SELECTED_CAMPIONATO_GIRONI_PUBBLICATI'
export const SET_SELECTED_CAMPIONATO_CALENDARI_PROVVISORI =
  '@@home/SET_SELECTED_CAMPIONATO_CALENDARI_PROVVISORI'
export const SET_SELECTED_CAMPIONATO_CALENDARI_PUBBLICATI =
  '@@home/SET_SELECTED_CAMPIONATO_CALENDARI_PUBBLICATI'
export const SET_SELECTED_CAMPIONATO_SPOSTAMENTI_GRATUITI =
  '@@home/SET_SELECTED_CAMPIONATO_SPOSTAMENTI_GRATUITI'

export const SET_AVAILABLE_CAMPIONATI = '@@home/SET_AVAILABLE_CAMPIONATI'
export const SET_SELECTED_CAMPIONATO = '@@home/SET_SELECTED_CAMPIONATO'
export const SET_AVAILABLE_CATEGORIE = '@@home/SET_AVAILABLE_CATEGORIE'
export const SET_SELECTED_CATEGORIA = '@@home/SET_SELECTED_CATEGORIA'
export const SET_AVAILABLE_GIRONI = '@@home/SET_AVAILABLE_GIRONI'
export const SET_SELECTED_GIRONE = '@@home/SET_SELECTED_GIRONE'
export const SET_AVAILABLE_TEAMS = '@@home/SET_AVAILABLE_TEAMS'
export const SET_SELECTED_TEAM = '@@home/SET_SELECTED_TEAM'
export const SET_AVAILABLE_NUMERI_GARA = '@@home/SET_AVAILABLE_NUMERI_GARA'
export const SET_SELECTED_NUMERO_GARA = '@@home/SET_SELECTED_NUMERO_GARA'
export const SET_AVAILABLE_PERIODI = '@@home/SET_AVAILABLE_PERIODI'
export const SET_SELECTED_PERIODO = '@@home/SET_SELECTED_PERIODO'
export const SET_SELECTED_ARBITRO = '@@home/SET_SELECTED_ARBITRO'
export const SET_SELECTED_SOCIETA = '@@home/SET_SELECTED_SOCIETA'
export const SET_SELECTED_NUMERI_GARA = '@@home/SET_SELECTED_NUMERI_GARA'
export const SET_SELECTED_DESIGNAZIONI = '@@home/SET_SELECTED_DESIGNAZIONI'

export const SET_DASHBOARD_STATS = '@@home/SET_DASHBOARD_STATS'
export const SET_OLD_PASSWORD = '@@home/SET_OLD_PASSWORD'
export const SET_NEW_PASSWORD = '@@home/SET_NEW_PASSWORD'
