import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'

import { colours } from '../settings/settings'

export const ColouredButton = styled(Button)(
  ({
    textcolour,
    textsize,
    textbold,
    borderwidth,
    bordercolour,
    backgroundcolour,
    hovercolour,
  }) => ({
    padding: '8px',
    color: textcolour || colours.black,
    fontSize: `${textsize || 1}em`,
    fontWeight: textbold,
    border: `${borderwidth || 1}px solid ${bordercolour || colours.black}`,
    background: backgroundcolour,
    ':hover': { backgroundColor: hovercolour || backgroundcolour },
  })
)

export const TransparentButton = styled(Button)(
  ({ textcolour, textsize, textbold, bordercolour }) => ({
    padding: '8px',
    color: textcolour || colours.black,
    fontSize: `${textsize || 1}em`,
    fontWeight: textbold,
    border: bordercolour && `1px solid ${bordercolour}`,
    ':hover': { backgroundColor: colours.greyVeryLight },
  })
)
