import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import fileDownload from 'js-file-download'
import {
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import DownloadIcon from '@mui/icons-material/Download'

import DialogMini from '../components/DialogMini'
import SelectionBar from '../components/SelectionBar'
import SortableTableHead from '../components/SortableTableHead'
import { ColouredButton } from '../components/Buttons'
import {
  colours,
  titlesRendiconto as titles,
  phrasesRendiconto as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { sendRequestBlob } from '../hooks/http-hook-blob'
import { utilsFunctions } from '../hooks/utils-functions'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'
import {
  SET_RENDICONTI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_RENDICONTO_MODE,
  SET_RENDICONTO,
  SET_ID_GARE_ARBITRO,
  SET_ID_GARE_RITARDO,
} from '../container/rendiconti/types'

const Rendiconti = ({
  admin,
  codiceUtente,
  dummyUpdate,
  affiliazioniLight,
  selectedSocieta,
  rendiconti,
  sortingColumn,
  sortingAscending,
  rendicontoMode,
  rendiconto,
  codiceSocietaAdd,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Rendiconti'

  let columnsRendiconti = [{ label: 'Stagione', sortable: false }]
  if (admin)
    columnsRendiconti = [
      ...columnsRendiconti,
      { label: 'Codice', sortable: true, sortingField: 'codiceSocieta' },
      { label: 'Società', sortable: true, sortingField: 'nomeSocieta' },
    ]
  if (!admin)
    columnsRendiconti = [
      ...columnsRendiconti,
      { label: 'Voci', sortable: false },
    ]
  columnsRendiconti = [
    ...columnsRendiconti,
    { label: 'Totale dovuto', sortable: false },
    { label: 'Totale pagato', sortable: false },
    { label: 'Bilancio', sortable: admin, sortingField: 'balance' },
    { label: 'Opzioni', sortable: false },
  ]

  const [loadingRendiconti, setLoadingRendiconti] = useState(false)
  const [rendicontiN, setRendicontiN] = useState()
  const [openRendiconto, setOpenRendiconto] = useState(false)
  const [openRendicontoAdd, setOpenRendicontoAdd] = useState(false)

  // state to manage feedback dialog
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false)
  const [feedbackDialogTitle, setFeedbackDialogTitle] = useState()
  const [feedbackDialogPhrase, setFeedbackDialogPhrase] = useState()
  const [feedbackDialogSuccess, setFeedbackDialogSuccess] = useState()

  const handleClickOpenRendiconto = id => {
    dispatch({
      type: SET_RENDICONTO_MODE,
      payload: admin ? 'update' : 'view',
    })
    dispatch({
      type: SET_RENDICONTO,
      payload: rendiconti?.find(r => r.id === id),
    })
    dispatch({
      type: SET_ID_GARE_ARBITRO,
      payload: rendiconti
        ?.find(r => r.id === id)
        ?.voci?.filter(
          v =>
            v.tipologia === 'needsReferee' || v.tipologia === 'requiresReferee'
        )
        ?.map(v => v.idGara),
    })
    dispatch({
      type: SET_ID_GARE_RITARDO,
      payload: rendiconti
        ?.find(r => r.id === id)
        ?.voci?.filter(v => v.tipologia === 'risultato-ritardo')
        ?.map(v => v.idGara),
    })
    setOpenRendiconto(true)
  }

  const handleClickOpenRendicontoAdd = () => setOpenRendicontoAdd(true)

  const handleClickOpenFeedbackDialog = (success, title, phrase) => {
    setFeedbackDialogSuccess(!!success)
    setFeedbackDialogTitle(title)
    setFeedbackDialogPhrase(phrase)
    setOpenFeedbackDialog(true)
  }

  const handleCloseRendiconto = () => {
    setOpenRendiconto(false)
    dispatch({ type: SET_RENDICONTO_MODE })
    dispatch({ type: SET_RENDICONTO })
  }

  const handleCloseRendicontoAdd = () => setOpenRendicontoAdd(false)

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false)
    setFeedbackDialogTitle()
    setFeedbackDialogPhrase()
    setFeedbackDialogSuccess()
  }

  const confirmRendiconto = () => {
    if (rendicontoMode === 'update') updateRendiconto()
    if (rendicontoMode === 'view') handleCloseRendiconto()
  }

  const { sumFun, formatEuro } = utilsFunctions()

  const totTotaleDovuto = () =>
    formatEuro(sumFun(rendicontiN?.map(r => r.totaleDovuto)) || 0)
  const totTotalePagato = () =>
    formatEuro(sumFun(rendicontiN?.map(r => r.totalePagato)) || 0)

  // fetch rendiconti
  useEffect(() => {
    const fetchRendiconti = async () => {
      setLoadingRendiconti(true)
      try {
        const rendicontiData = await sendRequest(
          `rendiconti${admin ? '/all' : `/societa/${codiceUtente}`}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!rendicontiData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare i rendiconti.',
          })
        } else {
          dispatch({ type: SET_RENDICONTI, payload: rendicontiData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setLoadingRendiconti(false)
    }
    if (codiceUtente) {
      fetchRendiconti()
    } else {
      dispatch({ type: SET_RENDICONTI })
    }
  }, [admin, codiceUtente, dispatch, dummyUpdate])

  // add rendiconto
  const addRendiconto = async () => {
    if (codiceSocietaAdd) {
      try {
        await sendRequest(
          'rendiconti/add',
          'POST',
          JSON.stringify({ codiceSocieta: codiceSocietaAdd }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseRendicontoAdd()
        handleClickOpenFeedbackDialog(
          true,
          titles.titleAddSuccess,
          phrases.phraseAddSuccess
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload: 'Impossibile procedere: società non trovata!',
      })
    }
  }

  // upgrade rendiconto
  const upgradeRendiconto = async id => {
    if (id) {
      try {
        await sendRequest(`rendiconti/${id}`, 'PUT', null, {
          Authorization: codiceUtente,
        })
        handleCloseRendiconto()
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } else {
      dispatch({
        type: SET_ERROR,
        payload: 'Impossibile procedere: informazioni non trovate!',
      })
    }
  }

  // update rendiconto
  const updateRendiconto = async () => {
    if (rendiconto?._id && rendiconto?.voci) {
      try {
        await sendRequest(
          `rendiconti/${rendiconto._id}`,
          'PATCH',
          JSON.stringify({ voci: rendiconto.voci }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseRendiconto()
        handleClickOpenFeedbackDialog(
          true,
          titles.titleUpdateSuccess,
          phrases.phraseUpdateSuccess
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload: 'Impossibile procedere: informazioni non trovate!',
      })
    }
  }

  // download file
  const generateAndDownloadExcel = async rendicontoId => {
    if (!rendicontoId) {
      dispatch({ type: SET_ERROR, payload: 'Rendiconto non trovato!' })
    } else {
      try {
        await sendRequest(
          `algoritmi/excel/rendiconti/${rendicontoId}`,
          'GET',
          null,
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        downloadFile()
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    }
  }

  const downloadFile = async (algorithm = '15_GenerateExcelRendiconto') => {
    try {
      const fileName = 'all'
      const checkFileData = await sendRequest(
        `algoritmi/checkfile/${algorithm}/XLSX/${fileName}`
      )
      const fileNames = checkFileData.fileNames
      if (fileNames.length === 1) {
        let fileName = fileNames[0]
        const fileData = await sendRequestBlob(
          `algoritmi/download/${algorithm}/${fileName}`
        )
        fileDownload(fileData, fileName)
      } else {
        handleClickOpenFeedbackDialog(false, 'Errore', 'File non trovato.')
      }
      handleClickOpenFeedbackDialog(
        true,
        'Download completato',
        'File scaricato correttamente.'
      )
    } catch (err) {
      console.log(err)
      handleClickOpenFeedbackDialog(false, 'Errore', err.message)
    }
  }

  // add "nomeSocieta" to rendiconti
  useEffect(() => {
    if (rendiconti && (!admin || affiliazioniLight))
      setRendicontiN(
        rendiconti
          ?.filter(r =>
            admin && selectedSocieta
              ? r.codiceSocieta ===
                affiliazioniLight?.find(a => a.id === selectedSocieta)
                  ?.codiceSocieta
              : true
          )
          ?.map(r => {
            r = { ...r, balance: r.totalePagato - r.totaleDovuto }
            if (admin) {
              const rSocieta = affiliazioniLight?.find(
                a => a.codiceSocieta === r?.codiceSocieta
              )
              return {
                ...r,
                nomeSocieta: rSocieta?.nomeSocieta,
                codiceSocieta: +rSocieta?.codiceSocieta,
              }
            } else {
              return r
            }
          })
          ?.reverse()
      )
  }, [admin, affiliazioniLight, rendiconti, selectedSocieta])

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>Rendiconti</h2>
        </center>
      </Grid>
      {admin && (
        <>
          &nbsp;
          <SelectionBar
            includeCampionato={false}
            includeCategoria={false}
            includeGirone={false}
            includeTeam={false}
            includeSocieta={admin}
          />
          <Grid container alignItems='center'>
            <Grid item xs={12} md={4}>
              <center>
                <ColouredButton
                  textcolour={colours.white}
                  textbold='bold'
                  backgroundcolour={colours.blue}
                  hovercolour={colours.blueDark}
                  onClick={handleClickOpenRendicontoAdd}
                >
                  Nuovo rendiconto
                </ColouredButton>
              </center>
            </Grid>
            {rendicontiN && (
              <>
                <Grid item xs={6} md={4}>
                  <center>
                    Totale dovuto: <b>{totTotaleDovuto()}</b>
                  </center>
                </Grid>
                <Grid item xs={6} md={4}>
                  <center>
                    Totale pagato: <b>{totTotalePagato()}</b>
                  </center>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
      {(loadingRendiconti && (
        <Grid item xs={12}>
          <center>
            <CircularProgress disableShrink={true} />
          </center>
        </Grid>
      )) ||
        (!rendiconti && (
          <Grid item xs={12}>
            <center>
              <h4>Elenco dei rendiconti non trovato.</h4>
            </center>
          </Grid>
        )) ||
        (rendicontiN?.length === 0 && (
          <Grid item xs={12}>
            <center>
              <h4>Nessun rendiconto trovato.</h4>
            </center>
          </Grid>
        )) || (
          <Grid container padding={3}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={rendicontiN}
                  setTable={setRendicontiN}
                  columns={columnsRendiconti}
                  sortingColumn={sortingColumn}
                  setSortingColumn={SET_SORTING_COLUMN}
                  sortingAscending={sortingAscending}
                  setSortingAscending={SET_SORTING_ASCENDING}
                />
                <TableBody>
                  {rendicontiN?.map(r => {
                    const isInDebit = r.balance < 0
                    const isInCredit = r.balance > 0
                    const isBalanced = r.balance === 0

                    const cellProps = {
                      align: 'center',
                      sx: { whiteSpace: 'nowrap' },
                    }

                    return (
                      <TableRow
                        key={r.id}
                        sx={{
                          cursor: 'pointer',
                          ':hover': { backgroundColor: colours.greyVeryLight },
                        }}
                        onClick={() => handleClickOpenRendiconto(r.id)}
                      >
                        <TableCell {...cellProps}>{r?.stagione}</TableCell>
                        {admin && (
                          <TableCell {...cellProps}>
                            {r?.codiceSocieta}
                          </TableCell>
                        )}
                        {admin && (
                          <TableCell {...cellProps}>{r?.nomeSocieta}</TableCell>
                        )}
                        {!admin && (
                          <TableCell {...cellProps}>
                            {r?.voci?.length}&nbsp;
                            {r?.voci?.length === 1 ? 'voce' : 'voci'}
                          </TableCell>
                        )}
                        <TableCell {...cellProps}>
                          {formatEuro(r?.totaleDovuto)}
                        </TableCell>
                        <TableCell {...cellProps}>
                          {formatEuro(r?.totalePagato)}
                        </TableCell>
                        <TableCell align='center'>
                          <Chip
                            variant={isBalanced ? 'outlined' : 'filled'}
                            label={
                              (isInDebit && 'da pagare') ||
                              (isInCredit && 'in credito') ||
                              (isBalanced && 'in pari') ||
                              '??'
                            }
                            color={
                              (isInDebit && 'warning') ||
                              (isInCredit && 'info') ||
                              (isBalanced && 'success') ||
                              'error'
                            }
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <Grid container item>
                            <Grid item xs>
                              <IconButton
                                style={{ color: colours.greenExcel }}
                                onClick={e => {
                                  e.stopPropagation()
                                  generateAndDownloadExcel(r._id)
                                }}
                              >
                                <Tooltip title='Scarica Excel'>
                                  <DownloadIcon />
                                </Tooltip>
                              </IconButton>
                            </Grid>
                            {admin && (
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.greyLight }}
                                  onClick={e => {
                                    e.stopPropagation()
                                    upgradeRendiconto(r._id)
                                  }}
                                >
                                  <Tooltip title='Aggiorna'>
                                    <RefreshIcon />
                                  </Tooltip>
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      <DialogMini
        open={openRendiconto}
        handleClose={handleCloseRendiconto}
        title={titles.titleRendiconto}
        textUndo={rendicontoMode === 'view' || !admin ? 'Chiudi' : 'Annulla'}
        textConfirm={rendicontoMode !== 'view' && admin && 'Conferma'}
        triggerFunction={confirmRendiconto}
        dialogRendiconto={true}
      />
      <DialogMini
        open={openRendicontoAdd}
        handleClose={handleCloseRendicontoAdd}
        title={titles.titleRendiconto}
        textUndo='Annulla'
        textConfirm='Conferma'
        triggerFunction={addRendiconto}
        dialogRendicontoAdd={true}
      />
      <DialogMini
        open={openFeedbackDialog}
        handleClose={handleCloseFeedbackDialog}
        title={feedbackDialogTitle}
        textContent={feedbackDialogPhrase}
        textConfirm='Ok'
        triggerFunction={handleCloseFeedbackDialog}
        colourBackground={!!feedbackDialogSuccess ? undefined : colours.red}
        colourHover={!!feedbackDialogSuccess ? undefined : colours.redDark}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  codiceUtente: state.home.codiceUtente,
  dummyUpdate: state.home.dummyUpdate,
  affiliazioniLight: state.home.affiliazioniLight,
  selectedSocieta: state.home.selectedSocieta,
  rendiconti: state.rendiconti.rendiconti,
  sortingColumn: state.rendiconti.sortingColumn,
  sortingAscending: state.rendiconti.sortingAscending,
  rendicontoMode: state.rendiconti.rendicontoMode,
  rendiconto: state.rendiconti.rendiconto,
  codiceSocietaAdd: state.rendiconti.codiceSocietaAdd,
})

const ConnectedRendiconti = connect(mapStateToProps)(Rendiconti)

export default ConnectedRendiconti
