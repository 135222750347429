import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

import Select from './Select'
import InputField from './InputField'
import DialogMini from './DialogMini'
import ButtonGroup from './ButtonGroup'
import AutoComplete from './AutoComplete'
import {
  colours,
  phrasesSpostamento as phrases,
  ORARI,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { errorFunctions } from '../hooks/error-functions'
import { sortFunctions } from '../hooks/sort-functions'
import { utilsFunctions } from '../hooks/utils-functions'
import { SET_ERROR } from '../container/home/types'
import {
  RESET_SPOSTAMENTO,
  SET_SELECTED_GARA,
  SET_SELECTED_FUNCTION,
  SET_NEW_DATA,
  SET_NEW_ORA,
  SET_NOTE,
  SET_CHECK_PAYMENT,
  SET_CODICE_SOCIETA_DOMANDA,
} from '../container/spostamenti/types'
import { SET_ISCRIZIONI } from '../container/iscrizioni/types'

const availableFunctions = ['sposta', 'sposta-inverti', 'inverti', 'rimanda']
const availableFunctionsLabels = [
  'Cambia data/ora',
  'Cambia data/ora e inverti le squadre',
  'Inverti le squadre',
  'Rimanda a data da destinarsi',
]

const DialogSpostamento = ({
  admin,
  codiceUtente,
  iscrizioniLight,
  affiliazioniLight,
  selectedCampionato,
  selectedCampionatoSpostamentiGratuiti,
  iscrizioni,
  selectedGara,
  selectedFunction,
  newData,
  newOra,
  note,
  checkPayment,
  codiceSocietaDomanda,
}) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState()

  const [availableCategorie, setAvailableCategorie] = useState()
  const [availableSquadre, setAvailableSquadre] = useState()
  const [availableGare, setAvailableGare] = useState()
  const [availableGareTeam, setAvailableGareTeam] = useState()
  const [availableGareCategoria, setAvailableGareCategoria] = useState()
  const [availableAffiliazioni, setAvailableAffiliazioni] = useState()
  const [selectedCategoria, setSelectedCategoria] = useState()
  const [selectedSquadra, setSelectedSquadra] = useState()
  const [selectedGaraInfo, setSelectedGaraInfo] = useState()

  // error functions
  const { errorMaxLength } = errorFunctions()
  const errorNote = useCallback(
    x => x && errorMaxLength(x, 100),
    [errorMaxLength]
  )

  // sort functions
  const { sortNumeriGara } = sortFunctions()
  const sortNumeriGaraM = useCallback(sortNumeriGara, [])

  // utils functions
  const { fromStringToDecimalHours } = utilsFunctions()

  const [squadraCasa, setSquadraCasa] = useState()
  const [squadraTrasferta, setSquadraTrasferta] = useState()

  const [openSposta, setOpenSposta] = useState(false)
  const [openSpostaInverti, setOpenSpostaInverti] = useState(false)
  const [openInverti, setOpenInverti] = useState(false)
  const [openRimanda, setOpenRimanda] = useState(false)

  const handleClickOpenSposta = () => setOpenSposta(true)
  const handleClickOpenSpostaInverti = () => setOpenSpostaInverti(true)
  const handleClickOpenInverti = () => setOpenInverti(true)
  const handleClickOpenRimanda = () => setOpenRimanda(true)

  const handleCloseSposta = () => setOpenSposta(false)
  const handleCloseSpostaInverti = () => setOpenSpostaInverti(false)
  const handleCloseInverti = () => setOpenInverti(false)
  const handleCloseRimanda = () => setOpenRimanda(false)

  // Set categorie
  useEffect(() => {
    setAvailableCategorie(
      availableGare
        ?.map(i => i.categoria)
        ?.filter((v, i, s) => s.indexOf(v) === i)
        ?.sort()
    )
  }, [availableGare])

  // Set categoria
  useEffect(() => {
    setSelectedCategoria(
      availableCategorie?.length === 1 ? availableCategorie[0] : undefined
    )
  }, [availableCategorie])

  // Set gare
  useEffect(() => {
    setAvailableGareCategoria(
      availableGare
        ?.filter(a => a.categoria === selectedCategoria)
        ?.sort((a, b) => sortNumeriGaraM(a.numeroGara, b.numeroGara))
    )
  }, [availableGare, selectedCategoria, sortNumeriGaraM])

  // Set squadre
  useEffect(() => {
    setAvailableSquadre(
      iscrizioni
        ?.filter(
          i =>
            (availableGare?.map(a => a.squadraCasaID)?.includes(i.id) ||
              availableGare?.map(a => a.squadraTrasfertaID)?.includes(i.id)) &&
            i.categoria === selectedCategoria
        )
        ?.map(i => i.id)
    )
  }, [availableGare, iscrizioni, selectedCategoria])

  // Set squadra
  useEffect(() => {
    setSelectedSquadra(
      availableSquadre?.length === 1 ? availableSquadre[0] : undefined
    )
  }, [availableSquadre])

  // Set gare
  useEffect(() => {
    setAvailableGareTeam(
      availableGare
        ?.filter(
          a =>
            a.squadraCasaID === selectedSquadra ||
            a.squadraTrasfertaID === selectedSquadra
        )
        ?.sort((a, b) => sortNumeriGaraM(a.numeroGara, b.numeroGara))
    )
  }, [availableGare, selectedSquadra, sortNumeriGaraM])

  // Set gara
  useEffect(() => {
    dispatch({
      type: SET_SELECTED_GARA,
      payload:
        availableGareTeam?.length === 1 ? availableGareTeam[0]._id : undefined,
    })
  }, [availableGareTeam, dispatch])

  // Update gara info
  useEffect(() => {
    setSelectedGaraInfo(availableGare?.find(c => c._id === selectedGara))
  }, [availableGare, selectedGara])

  // Set team names
  useEffect(() => {
    if (selectedGaraInfo?.squadraCasaID)
      setSquadraCasa(
        iscrizioniLight?.find(i => i.id === selectedGaraInfo.squadraCasaID)
      )
    if (selectedGaraInfo?.squadraTrasfertaID)
      setSquadraTrasferta(
        iscrizioniLight?.find(i => i.id === selectedGaraInfo.squadraTrasfertaID)
      )
  }, [iscrizioniLight, selectedGaraInfo])

  // Update affiliazioni
  useEffect(() => {
    setAvailableAffiliazioni(
      affiliazioniLight?.filter(
        i =>
          i.codiceSocieta === squadraCasa?.codiceSocieta ||
          i.codiceSocieta === squadraTrasferta?.codiceSocieta
      )
    )
  }, [affiliazioniLight, squadraCasa, squadraTrasferta])

  // Set function
  useEffect(() => {
    if (selectedGara && !selectedFunction && !!availableFunctions)
      dispatch({ type: SET_SELECTED_FUNCTION, payload: availableFunctions[0] })
  }, [dispatch, selectedFunction, selectedGara])

  // Reset inputs
  useEffect(() => {
    dispatch({ type: SET_NEW_DATA })
    dispatch({ type: SET_NEW_ORA })
    dispatch({ type: SET_CHECK_PAYMENT, payload: false })
  }, [dispatch, selectedGara])

  // fetch available gare spostamento
  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      try {
        const data = await sendRequest(
          `calendari/campionato/${selectedCampionato}${
            admin ? '' : `/societa/${codiceUtente}/spostamento`
          }`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!data || (!admin && !data.data)) {
          dispatch({
            type: SET_ERROR,
            payload:
              'Impossibile trovare le partite di cui chiedere lo spostamento.',
          })
        } else {
          setAvailableGare(admin ? data : data.data)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setIsLoading(false)
    }
    if (selectedCampionato && codiceUtente) {
      fetch()
    } else {
      setAvailableGare()
    }
  }, [admin, codiceUtente, dispatch, selectedCampionato])

  // fetch iscrizioni
  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      try {
        const data = await sendRequest(
          `iscrizioni/campionato/${selectedCampionato}`
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le squadre iscritte.',
          })
        } else {
          dispatch({ type: SET_ISCRIZIONI, payload: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setIsLoading(false)
    }
    if (admin && codiceUtente && selectedCampionato) {
      fetch()
    } else {
      if (admin) dispatch({ type: SET_ISCRIZIONI })
    }
  }, [admin, codiceUtente, dispatch, selectedCampionato])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: RESET_SPOSTAMENTO })
  }, [dispatch])

  return (
    <>
      &nbsp;
      {(!!isLoading && (
        <center>
          <CircularProgress disableShrink={true} />
        </center>
      )) ||
        ((!iscrizioni || iscrizioni.length === 0) && (
          <Grid item xs={12}>
            Nessuna squadra iscritta a questo campionato.
          </Grid>
        )) || (
          <Grid container spacing={3} direction='column'>
            <Grid item xs={12}>
              {(admin && (
                <>
                  Il presente modulo permette di richiedere uno spostamento per
                  conto di una società.
                  <br />
                  Questa funzionalità è pensata per <b>casi eccezionali</b> in
                  cui la società richiedente non può inserire lo spostamento da
                  sè.
                  <br />
                  La compilazione del modulo genera una mail alla squadra
                  avversaria, che dovrà accettare o rifiutare la richiesta.
                </>
              )) || (
                <>
                  Il presente modulo permette di richiedere lo spostamento di
                  una gara (cambio di data/orario, inversione di campo o rinvio
                  a data da destinarsi).
                  <br />
                  La compilazione del modulo genera una mail alla squadra
                  avversaria, che dovrà accettare o rifiutare la richiesta. Lo
                  spostamento avrà luogo solo se la squadra avversaria accetta.
                  <br />
                  <br />
                  <b>
                    La richiesta va compilata almeno 3 giorni (72 ore) prima
                    dell'orario di inizio gara.
                  </b>
                  <br />
                  <br />
                  Per domande o chiarimenti scrivere a{' '}
                  <a href='mailto:spostamenti@pgsmilano.org'>
                    spostamenti@pgsmilano.org
                  </a>
                  .
                </>
              )}
            </Grid>
            {!availableGare?.length ? (
              <Grid item xs={12}>
                <center>
                  <b>
                    <i>Nessuno spostamento possibile.</i>
                  </b>
                </center>
              </Grid>
            ) : (
              <>
                {admin && (
                  <Grid container item xs={12} spacing={2} alignItems='center'>
                    <Grid item xs={12} md={3}>
                      <Select
                        id='categoria'
                        label='Categoria'
                        value={selectedCategoria}
                        options={availableCategorie}
                        onChange={setSelectedCategoria}
                        errorText='Selezionare una categoria!'
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <AutoComplete
                        id='gara'
                        label='Gara'
                        value={selectedGara}
                        options={availableGareCategoria?.map(i => i._id)}
                        getOptionLabel={i =>
                          availableGareCategoria?.find(a => a._id === i)
                            ?.numeroGara
                        }
                        onChange={i =>
                          dispatch({ type: SET_SELECTED_GARA, payload: i })
                        }
                        errorText='Selezionare una gara!'
                        disabled={!selectedCategoria}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        id='codiceSocietaDomanda'
                        label='Società richiedente'
                        value={codiceSocietaDomanda}
                        options={availableAffiliazioni?.map(
                          i => i.codiceSocieta
                        )}
                        optionLabels={availableAffiliazioni?.map(
                          a => `${a.codiceSocieta} - ${a.nomeSocieta}`
                        )}
                        onChange={i =>
                          dispatch({
                            type: SET_CODICE_SOCIETA_DOMANDA,
                            payload: i,
                          })
                        }
                        errorText='Selezionare una società!'
                        disabled={!selectedGara}
                      />
                    </Grid>
                  </Grid>
                )}
                {!admin && (
                  <>
                    <Grid container item alignItems='center'>
                      <Grid item xs={5} md={3}>
                        <center>
                          <b>Categoria</b>
                        </center>
                      </Grid>
                      <Grid item xs={7} md={9}>
                        {availableCategorie?.length > 0 ? (
                          <ButtonGroup
                            list={availableCategorie}
                            value={selectedCategoria}
                            func={setSelectedCategoria}
                          />
                        ) : (
                          'Nessuna categoria trovata.'
                        )}
                      </Grid>
                    </Grid>
                    {!selectedCategoria ? (
                      <Grid item>
                        <center>
                          <b>
                            <em>Selezionare una categoria.</em>
                          </b>
                        </center>
                      </Grid>
                    ) : (
                      <>
                        <Grid container item alignItems='center'>
                          <Grid item xs={5} md={3}>
                            <center>
                              <b>Squadra</b>
                            </center>
                          </Grid>
                          <Grid item xs={7} md={9}>
                            {availableSquadre?.length > 0 ? (
                              <ButtonGroup
                                list={availableSquadre}
                                labels={index =>
                                  iscrizioni?.find(
                                    i => i.id === availableSquadre[index]
                                  )?.nomeSquadra
                                }
                                value={selectedSquadra}
                                func={setSelectedSquadra}
                              />
                            ) : (
                              'Nessuna squadra trovata.'
                            )}
                          </Grid>
                        </Grid>
                        {!selectedSquadra ? (
                          <Grid item>
                            <center>
                              <b>
                                <i>Selezionare una squadra.</i>
                              </b>
                            </center>
                          </Grid>
                        ) : (
                          <>
                            <Grid container item alignItems='center'>
                              <Grid item xs={5} md={3}>
                                <center>
                                  <b>Gara</b>
                                </center>
                              </Grid>
                              <Grid item xs={7} md={9}>
                                {availableGare?.length > 0 ? (
                                  <ButtonGroup
                                    list={availableGareTeam?.map(i => i._id)}
                                    labels={index =>
                                      availableGareTeam[index]?.numeroGara
                                    }
                                    value={selectedGara}
                                    func={i =>
                                      dispatch({
                                        type: SET_SELECTED_GARA,
                                        payload: i,
                                      })
                                    }
                                  />
                                ) : (
                                  'Nessuna gara trovata.'
                                )}
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    &nbsp;
                  </>
                )}
                {selectedSquadra && !selectedGara && (
                  <Grid item>
                    <center>
                      <b>
                        <i>Selezionare una gara.</i>
                      </b>
                    </center>
                  </Grid>
                )}
                {selectedGara && (
                  <Grid container item xs={12} spacing={3} alignItems='center'>
                    <Grid item xs={12} md={6}>
                      <center>
                        <b>
                          {squadraCasa?.nomeSquadra ||
                            selectedGaraInfo?.squadraCasaID}
                        </b>
                        &nbsp;-&nbsp;
                        <b>
                          {squadraTrasferta?.nomeSquadra ||
                            selectedGaraInfo?.squadraTrasfertaID}
                        </b>
                      </center>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <center>
                        {selectedGaraInfo?.data || 'Data da definire'}
                      </center>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <center>
                        {selectedGaraInfo?.ora || 'Ora da definire'}
                      </center>
                    </Grid>
                  </Grid>
                )}
                &nbsp;
                {!admin &&
                  !selectedCampionatoSpostamentiGratuiti &&
                  selectedGara &&
                  ['sposta', 'sposta-inverti'].includes(selectedFunction) &&
                  (!!selectedGaraInfo?.needsReferee ||
                    !!selectedGaraInfo?.requiresReferee) &&
                  selectedGaraInfo?.girone !== 'finali' &&
                  (!selectedGaraInfo?.data ||
                    !selectedGaraInfo?.ora ||
                    selectedGaraInfo?.data !== newData ||
                    Math.abs(
                      fromStringToDecimalHours(selectedGaraInfo?.ora) -
                        fromStringToDecimalHours(newOra)
                    ) > 3) && (
                    <Grid container item alignItems='center'>
                      <Grid item xs={2} md={1}>
                        <center>
                          <Checkbox
                            id='check-payment'
                            checked={!!checkPayment}
                            onChange={event => {
                              dispatch({
                                type: SET_CHECK_PAYMENT,
                                payload: event.target.checked,
                              })
                            }}
                          />
                        </center>
                      </Grid>
                      <Grid item xs={10} md={11}>
                        <b>
                          La società si impegna a versare la tassa per lo
                          spostamento.
                        </b>
                      </Grid>
                    </Grid>
                  )}
                {!!selectedGara && (
                  <Grid
                    container
                    item
                    xs={12}
                    columnSpacing={3}
                    alignItems='center'
                  >
                    <Grid item xs={10} md={5}>
                      <Select
                        id='tipologia'
                        value={selectedFunction}
                        options={availableFunctions}
                        optionLabels={availableFunctionsLabels}
                        label='Tipologia'
                        onChange={value =>
                          dispatch({
                            type: SET_SELECTED_FUNCTION,
                            payload: value,
                          })
                        }
                        errorText='Selezionare una tipologia!'
                      />
                    </Grid>
                    <Grid item xs={2} md={1}>
                      <center>
                        <IconButton
                          style={{ color: colours.grey }}
                          onClick={
                            selectedFunction === availableFunctions[0]
                              ? handleClickOpenSposta
                              : selectedFunction === availableFunctions[1]
                              ? handleClickOpenSpostaInverti
                              : selectedFunction === availableFunctions[2]
                              ? handleClickOpenInverti
                              : selectedFunction === availableFunctions[3]
                              ? handleClickOpenRimanda
                              : null
                          }
                        >
                          <Tooltip title='Maggiori informazioni'>
                            <InfoIcon />
                          </Tooltip>
                        </IconButton>
                      </center>
                    </Grid>
                    {[availableFunctions[0], availableFunctions[1]].includes(
                      selectedFunction
                    ) && (
                      <>
                        <Grid item xs={6} md={3}>
                          <center>
                            <InputField
                              id='data'
                              value={newData?.split('/').reverse().join('-')}
                              onChange={i =>
                                dispatch({
                                  type: SET_NEW_DATA,
                                  payload: i?.split('-').reverse().join('/'),
                                })
                              }
                              fullWidth
                              label='Nuova data'
                              errorText='Selezionare una data!'
                              errorFunc={x => !x}
                              type='date'
                            />
                          </center>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Select
                            id='ora'
                            value={newOra}
                            options={ORARI}
                            label='Nuovo orario'
                            onChange={i =>
                              dispatch({ type: SET_NEW_ORA, payload: i })
                            }
                            errorText='Selezionare un orario!'
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <InputField
                        id='note'
                        value={note}
                        onChange={i => dispatch({ type: SET_NOTE, payload: i })}
                        label='Note per la squadra avversaria'
                        errorText='Testo troppo lungo!'
                        errorFunc={errorNote}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        )}
      <DialogMini
        open={openSposta}
        handleClose={handleCloseSposta}
        title={availableFunctionsLabels[0]}
        textContent={phrases.phraseSposta}
        textConfirm='Ok'
        triggerFunction={handleCloseSposta}
      />
      <DialogMini
        open={openSpostaInverti}
        handleClose={handleCloseSpostaInverti}
        title={availableFunctionsLabels[1]}
        textContent={phrases.phraseSpostaInverti}
        textConfirm='Ok'
        triggerFunction={handleCloseSpostaInverti}
      />
      <DialogMini
        open={openInverti}
        handleClose={handleCloseInverti}
        title={availableFunctionsLabels[2]}
        textContent={phrases.phraseInverti}
        textConfirm='Ok'
        triggerFunction={handleCloseInverti}
      />
      <DialogMini
        open={openRimanda}
        handleClose={handleCloseRimanda}
        title={availableFunctionsLabels[3]}
        textContent={phrases.phraseRimanda}
        textConfirm='Ok'
        triggerFunction={handleCloseRimanda}
      />
    </>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  codiceUtente: state.home.codiceUtente,
  iscrizioniLight: state.home.iscrizioniLight,
  affiliazioniLight: state.home.affiliazioniLight,
  selectedCampionato: state.home.selectedCampionato,
  selectedCampionatoSpostamentiGratuiti:
    state.home.selectedCampionatoSpostamentiGratuiti,
  iscrizioni: state.iscrizioni.iscrizioni,
  selectedGara: state.spostamenti.selectedGara,
  selectedFunction: state.spostamenti.selectedFunction,
  newData: state.spostamenti.newData,
  newOra: state.spostamenti.newOra,
  note: state.spostamenti.note,
  checkPayment: state.spostamenti.checkPayment,
  codiceSocietaDomanda: state.spostamenti.codiceSocietaDomanda,
})

const ConnectedDialogSpostamento = connect(mapStateToProps)(DialogSpostamento)

export default ConnectedDialogSpostamento
