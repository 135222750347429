import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import fileDownload from 'js-file-download'
import {
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import EuroIcon from '@mui/icons-material/Euro'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'

import DialogMini from '../components/DialogMini'
import SelectionBar from '../components/SelectionBar'
import SortableTableHead from '../components/SortableTableHead'
import { ColouredButton } from '../components/Buttons'
import {
  colours,
  titlesRimborso as titles,
  phrasesRimborso as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { sendRequestBlob } from '../hooks/http-hook-blob'
import { utilsFunctions } from '../hooks/utils-functions'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'
import {
  SET_RIMBORSI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_RIMBORSO_MODE,
  SET_RIMBORSO,
} from '../container/rimborsi/types'

const Rimborsi = ({
  admin,
  arbitro,
  codiceUtente,
  infoUtente,
  dummyUpdate,
  selectedArbitro,
  selectedDesignazioni,
  rimborsi,
  sortingColumn,
  sortingAscending,
  rimborsoMode,
  rimborso,
  rimborsoIsPaid,
  arbitri,
}) => {
  const dispatch = useDispatch()

  document.title = 'PGS Milano - Rimborsi'

  let columnsRimborsi = [
    {
      label: 'Data creazione',
      sortable: true,
      sortingField: 'timeFirstInsert',
    },
  ]
  if (admin)
    columnsRimborsi = [
      ...columnsRimborsi,
      { label: 'Arbitro', sortable: true, sortingField: 'cognomenome' },
    ]
  columnsRimborsi = [...columnsRimborsi, { label: 'Gare', sortable: false }]
  if (arbitro)
    columnsRimborsi = [
      ...columnsRimborsi,
      { label: 'Compenso' },
      { label: 'Spese' },
    ]
  columnsRimborsi = [
    ...columnsRimborsi,
    { label: 'Totale', sortable: true, sortingField: 'totale' },
    { label: 'Stato', sortable: true, sortingField: 'isPaid' },
    { label: 'Opzioni', sortable: false },
  ]

  const [loadingRimborsi, setLoadingRimborsi] = useState(false)
  const [rimborsiN, setRimborsiN] = useState()
  const [allPaid, setAllPaid] = useState()
  const [openRimborso, setOpenRimborso] = useState(false)
  const [openPagato, setOpenPagato] = useState(false)
  const [deletingRimborso, setDeletingRimborso] = useState()
  const [openDeleteRimborso, setOpenDeleteRimborso] = useState(false)

  // state to manage feedback dialog
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false)
  const [feedbackDialogTitle, setFeedbackDialogTitle] = useState()
  const [feedbackDialogPhrase, setFeedbackDialogPhrase] = useState()
  const [feedbackDialogSuccess, setFeedbackDialogSuccess] = useState()

  const handleClickOpenRimborso = id => {
    dispatch({ type: SET_RIMBORSO, payload: rimborsi?.find(r => r.id === id) })
    setOpenRimborso(true)
  }

  const handleClickOpenPagato = id => {
    dispatch({ type: SET_RIMBORSO, payload: rimborsi?.find(r => r.id === id) })
    setOpenPagato(true)
  }

  const handleClickOpenDeleteRimborso = id => {
    setDeletingRimborso(rimborsi?.find(r => r.id === id))
    setOpenDeleteRimborso(true)
  }

  const handleClickOpenFeedbackDialog = (success, title, phrase) => {
    setFeedbackDialogSuccess(!!success)
    setFeedbackDialogTitle(title)
    setFeedbackDialogPhrase(phrase)
    setOpenFeedbackDialog(true)
  }

  const handleCloseRimborso = () => {
    setOpenRimborso(false)
    dispatch({ type: SET_RIMBORSO_MODE })
    dispatch({ type: SET_RIMBORSO })
  }

  const handleClosePagato = () => {
    setOpenPagato(false)
    dispatch({ type: SET_RIMBORSO })
  }

  const handleCloseDeleteRimborso = () => {
    setOpenDeleteRimborso(false)
    setDeletingRimborso()
  }

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false)
    setFeedbackDialogTitle()
    setFeedbackDialogPhrase()
    setFeedbackDialogSuccess()
  }

  const confirmRimborso = () => {
    if (rimborsoMode === 'select') selectDesignazioni()
    if (rimborsoMode === 'add') addRimborso()
    if (rimborsoMode === 'update') updateRimborso()
    if (rimborsoMode === 'view') handleCloseRimborso()
  }

  const confirmDeleteRimborso = () => {
    deleteRimborso()
    handleCloseDeleteRimborso()
  }

  const { fromDateTimeToString, sumFun, formatEuro } = utilsFunctions()

  const totTotaleDovuto = () =>
    formatEuro(
      sumFun(rimborsiN?.filter(r => !r.isPaid)?.map(r => r.totale)) || 0
    )
  const totTotalePagato = () =>
    formatEuro(
      sumFun(rimborsiN?.filter(r => r.isPaid)?.map(r => r.totale)) || 0
    )

  // fetch rimborsi
  useEffect(() => {
    const fetchRimborsi = async () => {
      setLoadingRimborsi(true)
      try {
        const rimborsiData = await sendRequest(
          `rimborsi${admin ? '/all' : `/arbitro/${infoUtente.id}`}`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )

        if (!rimborsiData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare i rimborsi.',
          })
        } else {
          dispatch({ type: SET_RIMBORSI, payload: rimborsiData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setLoadingRimborsi(false)
    }
    if ((admin || (arbitro && infoUtente?.id)) && codiceUtente) {
      fetchRimborsi()
    } else {
      dispatch({ type: SET_RIMBORSI })
    }
  }, [admin, arbitro, codiceUtente, dispatch, dummyUpdate, infoUtente])

  // select designazioni
  const selectDesignazioni = async () => {
    if (selectedDesignazioni?.length) {
      dispatch({
        type: SET_RIMBORSO,
        payload: {
          ...rimborso,
          designazioni: selectedDesignazioni?.map(d => ({
            idDesignazione: d,
            diaria: 20,
          })),
        },
      })
      dispatch({ type: SET_RIMBORSO_MODE, payload: 'add' })
    } else {
      dispatch({ type: SET_ERROR, payload: 'Nessuna gara selezionata!' })
    }
  }

  // add rimborso
  const addRimborso = async () => {
    if (rimborso?.designazioni?.length) {
      try {
        await sendRequest(
          `rimborsi/add`,
          'POST',
          JSON.stringify({
            codiceArbitro: codiceUtente,
            designazioni: rimborso?.designazioni,
            costoKm: rimborso?.costoKm,
            importoLordoAnnuoPGS: rimborso?.importoLordoAnnuoPGS,
            importoLordoAnnuoAltri: rimborso?.importoLordoAnnuoAltri,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseRimborso()
        handleClickOpenFeedbackDialog(
          true,
          titles.titleAddSuccess,
          phrases.phraseAddSuccess
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    }
  }

  // update rimborso
  const updateRimborso = async () => {
    if (
      rimborso?.id &&
      rimborso?.designazioni &&
      typeof rimborso?.importoLordoAnnuoPGS === 'number' &&
      typeof rimborso?.importoLordoAnnuoAltri === 'number'
    ) {
      try {
        await sendRequest(
          `rimborsi/${rimborso.id}`,
          'PATCH',
          JSON.stringify({
            designazioni: rimborso.designazioni,
            importoLordoAnnuoPGS: rimborso.importoLordoAnnuoPGS,
            importoLordoAnnuoAltri: rimborso.importoLordoAnnuoAltri,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseRimborso()
        handleClickOpenFeedbackDialog(
          true,
          titles.titleUpdateSuccess,
          phrases.phraseUpdateSuccess
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          'Impossibile aggiornare il rimborso: informazioni non trovate!',
      })
    }
  }

  // update isPaid
  const updatePagato = async () => {
    if (codiceUtente && rimborso?.id) {
      try {
        await sendRequest(
          `rimborsi/is-paid/${rimborso.id}`,
          'PATCH',
          JSON.stringify({ isPaid: rimborsoIsPaid }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleClosePagato()
        handleClickOpenFeedbackDialog(
          true,
          titles.titlePagatoSuccess,
          phrases.phrasePagatoSuccess
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload:
          'Impossibile aggiornare lo stato del pagamento: nessun rimborso selezionato!',
      })
    }
  }

  // delete rimborso
  const deleteRimborso = async () => {
    if (codiceUtente && deletingRimborso?.id) {
      try {
        await sendRequest(`rimborsi/${deletingRimborso.id}`, 'DELETE', null, {
          Authorization: codiceUtente,
        })
        handleClickOpenFeedbackDialog(
          true,
          titles.titleDeleteSuccess,
          phrases.phraseDeleteSuccess
        )
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    }
  }

  // add "cognomenome" to rimborsi
  useEffect(() => {
    if (rimborsi && (!admin || arbitri))
      setRimborsiN(
        rimborsi
          ?.filter(r =>
            admin && selectedArbitro ? r.idArbitro === selectedArbitro : true
          )
          ?.map(r => {
            if (admin) {
              const rArbitro = arbitri?.find(a => a.id === r?.idArbitro)
              return {
                ...r,
                cognomenome: `${rArbitro.cognome} ${rArbitro.nome}`,
              }
            } else {
              return r
            }
          })
          ?.reverse()
      )
  }, [admin, arbitri, rimborsi, selectedArbitro])

  // download file
  const generateAndDownloadExcel = async rimborsoId => {
    if (!rimborsoId) {
      dispatch({ type: SET_ERROR, payload: 'Rimborso non trovato!' })
    } else {
      try {
        await sendRequest(
          `algoritmi/excel/rimborsi/${rimborsoId}`,
          'GET',
          null,
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        downloadFile()
      } catch (err) {
        console.log(err)
        handleClickOpenFeedbackDialog(false, 'Errore', err.message)
      }
    }
  }

  const downloadFile = async (algorithm = '13_GenerateExcelRimborso') => {
    try {
      const fileName = 'all'
      const checkFileData = await sendRequest(
        `algoritmi/checkfile/${algorithm}/XLSX/${fileName}`
      )
      const fileNames = checkFileData.fileNames
      if (fileNames.length === 1) {
        let fileName = fileNames[0]
        const fileData = await sendRequestBlob(
          `algoritmi/download/${algorithm}/${fileName}`
        )
        fileDownload(fileData, fileName)
      } else {
        handleClickOpenFeedbackDialog(false, 'Errore', 'File non trovato.')
      }
      handleClickOpenFeedbackDialog(
        true,
        'Download completato',
        'File scaricato correttamente.'
      )
    } catch (err) {
      console.log(err)
      handleClickOpenFeedbackDialog(false, 'Errore', err.message)
    }
  }

  // update allPaid
  useEffect(() => {
    if (rimborsiN?.length) setAllPaid(rimborsiN.every(r => r.isPaid))
  }, [rimborsiN])

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>Rimborsi</h2>
        </center>
      </Grid>
      {admin && (
        <>
          &nbsp;
          <SelectionBar
            includeCampionato={false}
            includeCategoria={false}
            includeGirone={false}
            includeTeam={false}
            includeArbitro={admin}
          />
        </>
      )}
      {admin && rimborsiN && (
        <Grid container alignItems='center'>
          <Grid item xs={6}>
            <center>
              Totale da rimborsare: <b>{totTotaleDovuto()}</b>
            </center>
          </Grid>
          <Grid item xs={6}>
            <center>
              Totale rimborsato: <b>{totTotalePagato()}</b>
            </center>
          </Grid>
        </Grid>
      )}
      {arbitro && (
        <Grid item xs={12}>
          <center>
            <ColouredButton
              textcolour={colours.white}
              textbold='bold'
              backgroundcolour={colours.blue}
              hovercolour={colours.blueDark}
              onClick={() => {
                dispatch({ type: SET_RIMBORSO_MODE, payload: 'select' })
                handleClickOpenRimborso()
              }}
            >
              Nuovo rimborso
            </ColouredButton>
          </center>
        </Grid>
      )}
      {(loadingRimborsi && (
        <Grid item xs={12}>
          <center>
            <CircularProgress disableShrink={true} />
          </center>
        </Grid>
      )) ||
        (!rimborsi && (
          <Grid item xs={12}>
            <center>
              <h4>Elenco dei rimborsi non trovato.</h4>
            </center>
          </Grid>
        )) ||
        (rimborsiN?.length === 0 && (
          <Grid item xs={12}>
            <center>
              <h4>Nessun rimborso trovato.</h4>
            </center>
          </Grid>
        )) || (
          <Grid container padding={3}>
            <Grid item xs={12} paddingX={5}>
              Cliccando su ciascuna riga, si possono vedere i dettagli e
              modificare i dati inseriti (se non è ancora avvenuto il
              pagamento).
              <br />
              Cliccando sulla freccia verde a destra, si scarica il file Excel
              del relativo rimborso spese.
              {admin && (
                <>
                  <br />
                  Cliccando sulla €, si può aggiornare lo stato del pagamento.
                </>
              )}
              <br />
              Cliccando sul cestino rosso a destra, si elimina il rimborso dalla
              piattaforma (se non è ancora avvenuto il pagamento).
            </Grid>
            &nbsp;
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={rimborsiN?.map(r => {
                    return { ...r, isPaid: !!r.isPaid }
                  })}
                  setTable={t => dispatch({ type: SET_RIMBORSI, payload: t })}
                  columns={columnsRimborsi}
                  sortingColumn={sortingColumn}
                  setSortingColumn={SET_SORTING_COLUMN}
                  sortingAscending={sortingAscending}
                  setSortingAscending={SET_SORTING_ASCENDING}
                />
                <TableBody>
                  {rimborsiN?.map(r => (
                    <TableRow
                      key={r.id}
                      sx={{
                        cursor: 'pointer',
                        ':hover': { backgroundColor: colours.greyVeryLight },
                      }}
                      onClick={() => {
                        dispatch({
                          type: SET_RIMBORSO_MODE,
                          payload: r.isPaid ? 'view' : 'update',
                        })
                        handleClickOpenRimborso(r.id)
                      }}
                    >
                      <TableCell align='center'>
                        {fromDateTimeToString(
                          new Date(r?.timeFirstInsert),
                          true
                        )}
                      </TableCell>
                      {admin && (
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {r?.cognomenome}
                        </TableCell>
                      )}
                      <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                        {r?.designazioni?.length}&nbsp;
                        {r?.designazioni?.length === 1 ? 'gara' : 'gare'}
                      </TableCell>
                      {arbitro && (
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {formatEuro(r?.totaleDiaria)}
                        </TableCell>
                      )}
                      {arbitro && (
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {formatEuro(
                            r?.totaleKmPercorsi * r?.costoKm + r?.totaleSpese
                          )}
                        </TableCell>
                      )}
                      <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                        {formatEuro(r?.totale)}
                      </TableCell>
                      <TableCell align='center'>
                        <Chip
                          sx={{ cursor: 'pointer' }}
                          variant={r.isPaid ? 'outlined' : 'filled'}
                          label={r.isPaid ? 'pagato' : 'da pagare'}
                          color={r.isPaid ? 'success' : 'warning'}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <Grid container item>
                          <Grid item xs>
                            <IconButton
                              style={{ color: colours.greenExcel }}
                              onClick={e => {
                                e.stopPropagation()
                                generateAndDownloadExcel(r.id)
                              }}
                            >
                              <Tooltip title='Scarica Excel'>
                                <DownloadIcon />
                              </Tooltip>
                            </IconButton>
                          </Grid>
                          {admin && (
                            <Grid item xs>
                              <IconButton
                                style={{
                                  color: r.isPaid
                                    ? colours.greenExcel
                                    : colours.greyVeryLight,
                                }}
                                onClick={e => {
                                  e.stopPropagation()
                                  handleClickOpenPagato(r.id)
                                }}
                              >
                                <Tooltip title='Aggiorna pagamento'>
                                  <EuroIcon />
                                </Tooltip>
                              </IconButton>
                            </Grid>
                          )}
                          {!allPaid && (
                            <Grid item xs>
                              {!r.isPaid && (
                                <IconButton
                                  style={{ color: colours.red }}
                                  onClick={e => {
                                    e.stopPropagation()
                                    handleClickOpenDeleteRimborso(r.id)
                                  }}
                                >
                                  <Tooltip title='Elimina'>
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      <DialogMini
        open={openRimborso}
        handleClose={handleCloseRimborso}
        title={titles.titleRimborso}
        textUndo={(rimborsoMode === 'view' && 'Chiudi') || 'Annulla'}
        textConfirm={rimborsoMode !== 'view' && 'Conferma'}
        triggerFunction={confirmRimborso}
        dialogRimborso={true}
      />
      <DialogMini
        open={openPagato}
        handleClose={handleClosePagato}
        title={titles.titlePagato}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={updatePagato}
        dialogRimborsoPagato={true}
      />
      <DialogMini
        open={openDeleteRimborso}
        handleClose={handleCloseDeleteRimborso}
        title={titles.titleDelete}
        textContent={phrases.phraseDelete}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={confirmDeleteRimborso}
        colourBackground={colours.red}
        colourHover={colours.redDark}
      />
      <DialogMini
        open={openFeedbackDialog}
        handleClose={handleCloseFeedbackDialog}
        title={feedbackDialogTitle}
        textContent={feedbackDialogPhrase}
        textConfirm='Ok'
        triggerFunction={handleCloseFeedbackDialog}
        colourBackground={!!feedbackDialogSuccess ? undefined : colours.red}
        colourHover={!!feedbackDialogSuccess ? undefined : colours.redDark}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  admin: state.home.admin,
  arbitro: state.home.arbitro,
  codiceUtente: state.home.codiceUtente,
  infoUtente: state.home.infoUtente,
  dummyUpdate: state.home.dummyUpdate,
  selectedArbitro: state.home.selectedArbitro,
  selectedDesignazioni: state.home.selectedDesignazioni,
  rimborsi: state.rimborsi.rimborsi,
  sortingColumn: state.rimborsi.sortingColumn,
  sortingAscending: state.rimborsi.sortingAscending,
  rimborsoMode: state.rimborsi.rimborsoMode,
  rimborso: state.rimborsi.rimborso,
  rimborsoIsPaid: state.rimborsi.rimborsoIsPaid,
  arbitri: state.arbitri.arbitri,
})

const ConnectedRimborsi = connect(mapStateToProps)(Rimborsi)

export default ConnectedRimborsi
